import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import {
  HELPFUL_BTN,
  HELPFUL_COUNT,
  NOT_HELPFUL_BTN,
  NOT_HELPFUL_COUNT,
  PENDING,
} from '../../../common/constants/data-hooks';
import { VotesState } from '../../../common/store/reviews/votes/review-vote-types';
import { useApi } from '../api-provider/use-api';
import { getUpvotesDisplayed } from '../../../common/store/reviews/votes/review-vote-selectors';
import ThumbUp from 'wix-ui-icons-common/on-stage/ThumbUp';
import ThumbUpFill from 'wix-ui-icons-common/on-stage/ThumbUpFill';
import { st, classes } from './voting-buttons.st.css';
import classNames from 'classnames';

export const VotingButtons: React.FC<{
  votesState: VotesState;
  reviewId: string;
  reviewsVoting: 'full' | 'upvotesOnly';
  className?: string;
}> = ({ reviewId, votesState, reviewsVoting, className }) => {
  const t = useTranslate();
  const { voteOnReview, biClickHelpfulBtn } = useApi((_state, actions) => ({
    voteOnReview: actions.voteOnReview,
    biClickHelpfulBtn: actions.biClickHelpfulBtn,
  }));
  const { upvotes, downvotes, pending } = getUpvotesDisplayed(votesState);

  return (
    <div className={st(classes.root, className)}>
      <button
        onClick={() => {
          voteOnReview({ reviewId, clicked: 'UPVOTE' });
          biClickHelpfulBtn({ action: 'upvote', reviewId });
        }}
        data-hook={classNames(HELPFUL_BTN, pending && PENDING)}
        className={st(classes.action, { isActive: upvotes.marked })}
        aria-pressed={upvotes.marked}
      >
        {upvotes.marked ? (
          <ThumbUpFill className={classes.wasThisHelpfulIcon} />
        ) : (
          <ThumbUp className={st(classes.wasThisHelpfulIcon)} />
        )}
        <div className={classes.buttonLabel} data-hook={HELPFUL_COUNT}>
          {t('review.was-this-helpful.yes', { count: upvotes.score })}
        </div>
      </button>
      {reviewsVoting === 'full' ? (
        <button
          data-hook={classNames(NOT_HELPFUL_BTN, pending && PENDING)}
          onClick={() => {
            voteOnReview({ reviewId, clicked: 'DOWNVOTE' });
            biClickHelpfulBtn({ action: 'downvote', reviewId });
          }}
          className={st(classes.action, { isActive: downvotes.marked }, classes.downvoteButton)}
          aria-pressed={downvotes.marked}
        >
          {downvotes.marked ? (
            <ThumbUpFill className={st(classes.wasThisHelpfulIcon, classes.notHelpfulIcon)} />
          ) : (
            <ThumbUp className={st(classes.wasThisHelpfulIcon, classes.notHelpfulIcon)} />
          )}
          <div className={classes.buttonLabel} data-hook={NOT_HELPFUL_COUNT}>
            {t('review.was-this-helpful.no', { count: downvotes.score })}
          </div>
        </button>
      ) : null}
    </div>
  );
};

export default VotingButtons;
