import React, { useEffect } from 'react';
import ConfirmationModal from '../base/confirmation-modal';
import { useApi } from '~reviews/Widget/components/api-provider/use-api';

export const PendingReviewModal = ({
  onClose,
  reviewId,
}: {
  onClose: () => void;
  reviewId: string;
}) => {
  const { biClickClosePendingReviewModal, biShowPendingReviewModal, biReviewSubmittedForApproval } =
    useApi((_, actions) => ({
      biClickClosePendingReviewModal: actions.biClickClosePendingReviewModal,
      biShowPendingReviewModal: actions.biShowPendingReviewModal,
      biReviewSubmittedForApproval: actions.biReviewSubmittedForApproval,
    }));
  useEffect(() => {
    biShowPendingReviewModal({ reviewId });
    biReviewSubmittedForApproval({ reviewId });
  }, []);
  const handleClick = (origin: 'ok' | 'x') => () => {
    biClickClosePendingReviewModal({ origin, reviewId });
    onClose();
  };
  return (
    <ConfirmationModal
      theme="notification"
      titleKey="pending-review-modal.title"
      messageKey="pending-review-modal.message"
      confirmKey="pending-review-modal.confirm"
      onConfirm={handleClick('ok')}
      onCancel={handleClick('x')}
    />
  );
};
