import React from 'react';
import { Dialog } from '../../_common/dialog/dialog';
import { ReviewGallery } from '../gallery';
import { classes, st } from './gallery-modal-mobile.st.css';
import { GalleryModalProps } from './index';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { MEDIA_MODAL } from '~reviews/common/constants/data-hooks';
import { GALLERY_CONSTS } from 'pro-gallery';

export const GalleryModalMobile: React.FC<GalleryModalProps> = ({
  onClose,
  review: { content },
  activeIndex,
}) => {
  const options = {
    layoutParams: {
      structure: {
        galleryLayout: GALLERY_CONSTS.layout.SLIDESHOW,
      },
      navigationArrows: {
        position: 'ON_GALLERY' as const,
      },
    },
  };
  const t = useTranslate();
  return (
    <Dialog
      onClose={onClose}
      childrenWrapperClassName={classes.dialog}
      className={st(classes.root)}
      contentClassName={classes.dialogContent}
      aria-label={t('review.media-aria-label')}
      data-hook={MEDIA_MODAL}
    >
      <ReviewGallery media={content.media} activeIndex={activeIndex} options={options} isMobile />
    </Dialog>
  );
};
