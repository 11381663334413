import React from 'react';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import '../../../common/services/polyfills';
import { WidgetProps, useEnvironment, useErrorBoundary } from '@wix/yoshi-flow-editor';
import { ReviewsProps } from '~reviews/common/common-types';
import AppWrapper from './components/app-wrapper/app-wrapper';
import { ModalProvider } from './components/modals/use-modal';
import App from './components/app/app';
import { ApiProvider } from './components/api-provider/api-provider';
import { FallbackState } from './components/error-handling/fallback-state';

// Uncomment to enable "why-did-you-render" logs

// const whyDidYouRender = require('@welldone-software/why-did-you-render');
// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// });

const WidgetRoot: React.FC<WidgetProps<ReviewsProps>> = (props) => {
  const { isMobile, isRTL } = useEnvironment();
  const { error, errorId } = useErrorBoundary();
  if (error) {
    return <FallbackState errorCode={errorId} />;
  }

  return (
    <TPAComponentsProvider
      // eslint-disable-next-line react-hooks/rules-of-hooks
      value={React.useMemo(() => ({ mobile: isMobile, rtl: isRTL }), [isMobile, isRTL])}
    >
      <ApiProvider {...props}>
        <AppWrapper>
          <ModalProvider>
            <App />
          </ModalProvider>
        </AppWrapper>
      </ApiProvider>
    </TPAComponentsProvider>
  );
};

export default WidgetRoot;
