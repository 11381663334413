import React from 'react';
import ToastRoot from '../toast/toast-root';
import PreviewBlocker from '~commons/settings/components/components/preview-blocker/preview-blocker';
import ModalRoot from '../modals/modal-root';
import { getIsPreview, getIsEditor } from '../../../common/store/base-params/base-params-selectors';
import { useApi } from '../api-provider/use-api';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const OverlayLayerContainer = () => {
  const { isMobile } = useEnvironment();
  const { isPreview, isEditor } = useApi((state) => ({
    isPreview: getIsPreview(state),
    isEditor: getIsEditor(state),
  }));

  return (
    <React.Fragment>
      {(isPreview || isEditor) && <PreviewBlocker isMobile={isMobile} />}
      <ModalRoot />
      <ToastRoot />
    </React.Fragment>
  );
};

export default OverlayLayerContainer;
