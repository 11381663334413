import React from 'react';
import { ThreeDotsLoader } from 'wix-ui-tpa/cssVars';
import { style, classes } from './submit-cancel.st.css';
import { ButtonExtended } from '../button-extended/button-extended';
import { CANCEL_BTN, SUBMIT_BTN } from './data-hooks';

export const SubmitCancel: React.FC<{
  disableSubmit?: boolean;
  onCancel?: () => void;
  isPending: boolean;
  className?: string;
  buttonSize?: 'medium' | 'tiny';
  submitText: string;
  cancelText: string;
  dataHook?: string;
}> = ({
  disableSubmit,
  onCancel,
  isPending,
  className,
  buttonSize = 'medium',
  submitText,
  cancelText,
  dataHook,
}) => {
  return (
    <div className={style(classes.root, { isPending }, className)} data-hook={dataHook}>
      {onCancel && (
        <ButtonExtended
          className={classes.cancelButton}
          disabled={isPending}
          onClick={onCancel}
          size={buttonSize}
          type="button"
          mapping="secondary"
          data-hook={CANCEL_BTN}
        >
          {cancelText}
        </ButtonExtended>
      )}
      <ButtonExtended
        className={classes.submitButton}
        disabled={isPending || disableSubmit}
        size={buttonSize}
        data-hook={SUBMIT_BTN}
        type="submit"
        mapping="primary"
      >
        {isPending && <ThreeDotsLoader className={classes.threeDotsLoader} />}
        <span className={style(classes.submitButtonText)}>{submitText}</span>
      </ButtonExtended>
    </div>
  );
};
