import { StyleParamType, wixColorParam, wixFontParam, IStyleParam } from '@wix/tpa-settings';
import parseColor from 'color';

export type FormStylesParams = {
  formStarColor: StyleParamType.Color;
  formInputBgColor: StyleParamType.Color;
  formInputBorderColor: StyleParamType.Color;
  formInputCornerRadius: StyleParamType.Number;
  formInputBorderWidth: StyleParamType.Number;
  formCornerRadius: StyleParamType.Number;
  formBorderColor: StyleParamType.Color;
  formBorderWidth: StyleParamType.Number;
  formBgColor: StyleParamType.Color;
  formInputTextFont: StyleParamType.Font;
  formInputTextFontSize: StyleParamType.Number;
  formInputTextColor: StyleParamType.Color;
  formLabelTextFont: StyleParamType.Font;
  formLabelTextColor: StyleParamType.Color;
  formButtonBgColor: StyleParamType.Color;
  formButtonTextFont: StyleParamType.Font;
  formButtonTextColor: StyleParamType.Color;
  formButtonCornerRadius: StyleParamType.Number;
  formSidePadding: StyleParamType.Number;
  formTopBottomPadding: StyleParamType.Number;
  // -------Hover state styles-------//
  hoverFormStarColor: StyleParamType.Color;
  hoverFormInputBgColor: StyleParamType.Color;
  hoverFormInputBorderColor: StyleParamType.Color;
  hoverFormInputTextColor: StyleParamType.Color;
  // -------Error State styles-------//
  errorFormColor: StyleParamType.Color;
};

export const reviewFormStylesParams: {
  [P in keyof FormStylesParams]: Omit<IStyleParam<FormStylesParams[P]>, 'name'>;
} = {
  formStarColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  formInputBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  formInputBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  formInputCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    // only changed in desktop, keep this always to zero in mobile
    inheritDesktop: false,
  },
  formButtonCornerRadius: { type: StyleParamType.Number, getDefaultValue: () => 0 },
  formSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    // mobile should not follow desktop
    inheritDesktop: false,
  },
  formTopBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    // mobile should not follow desktop
    inheritDesktop: false,
  },
  formBgColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  formBorderColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5', 0.3) },
  formInputBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formBorderWidth: { type: StyleParamType.Number, getDefaultValue: () => 1 },
  formInputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  // mobile
  formInputTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: (props) => {
      const formInputTextFont = props.getStyleParamValue(
        { ...reviewFormStylesParams.formInputTextFont, key: 'formInputTextFont' },
        {
          isMobile: props.isMobile,
          isRTL: props.isRTL,
        },
      );
      return formInputTextFont.size;
    },
  },
  formInputTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formLabelTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  formLabelTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-5') },
  formButtonBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  formButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  formButtonTextColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  // -------Hover state styles-------//
  hoverFormStarColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  hoverFormInputBgColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  hoverFormInputBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  hoverFormInputTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  // -------Error State styles-------//
  errorFormColor: {
    type: StyleParamType.Color,
    getDefaultValue: () => ({
      value: parseColor('#DF3131').toString(),
      opacity: 1,
      name: null,
    }),
  },
};
