import { ConnectionState } from './dev-tools-types';

export const wrapWithDevConnectionState = <T extends Function>(
  fn: T,
  getDevState: () => ConnectionState,
  rejectWith: (status: number, response?: any) => any = (status) => ({ status }),
): T =>
  ((...args: any[]) => {
    const devState = getDevState();
    switch (devState.type) {
      case 'BROKEN': {
        const { status, delay, response } = devState;
        return new Promise((_resolve, reject) =>
          // eslint-disable-next-line prefer-promise-reject-errors
          setTimeout(() => reject(rejectWith(status, response)), delay * 1000),
        );
      }
      case 'DELAYED': {
        const { delay } = devState;
        return Promise.all([fn(...args), new Promise((r) => setTimeout(r, delay * 1000))]).then(
          ([result]) => result,
        );
      }
      default: {
        return fn(...args);
      }
    }
  }) as any;

// For sled test debugging
export const wrapWithErrorTracking =
  <T extends (...args: any[]) => Promise<any>>(trackError?: (e: any, args: any[]) => void) =>
  (fn: T): T =>
    trackError
      ? (((...args: any[]) =>
          fn(...args).catch((e: any) => {
            trackError(e, args);
            throw e;
          })) as any)
      : fn;
