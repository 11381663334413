import { unreachable } from '~/ts-utils';

export const DELETE = 0 as const;
export const SET = 1 as const;
export const UPDATE = 2 as const;

type Leaf = { t: typeof DELETE } | { t: typeof SET; v: any } | { t: typeof UPDATE; v: Patch };

type Patch = { [key: string]: Leaf };

export const applyPatch = <T1 extends {}, T2 extends T1>(a: T1, patch: Patch): T2 => {
  // @ts-expect-error
  const result: T2 = { ...a };
  for (const key in patch) {
    const leaf = patch[key];
    switch (leaf.t) {
      case SET: {
        // @ts-expect-error
        result[key] = leaf.v;
        break;
      }
      case DELETE: {
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete result[key];
        break;
      }
      case UPDATE: {
        // @ts-expect-error
        result[key] = applyPatch(a[key], leaf.v);
        break;
      }
      default:
        throw unreachable(leaf);
    }
  }
  return result;
};
