import { ReviewsState } from '../reviews-types';
import { ReviewState } from './review-state-types';

export const getReviewState = (
  reviewId: string,
  resourceState?: ReviewsState,
): ReviewState | undefined => {
  if (resourceState?.type !== 'READY') {
    return undefined;
  }
  return resourceState.userReview.type === 'CREATED' &&
    resourceState.userReview.review.reviewId === reviewId
    ? resourceState.userReview.review
    : resourceState.reviewsById[reviewId];
};
