import React from 'react';
import { State } from '../../../common/store/store-types';
import { applyPatch } from './apply-patch';
import { StateWrapper } from '../../../common/common-types';
import { unreachable } from '~/ts-utils';
import { useEnvironment } from '@wix/yoshi-flow-editor';

// @TODO should we have 'undefined' as one of state values in its type?
const STATE_FALLBACK = undefined;

/*
 * Hook which receives state change 'messages' via stateWrapper and emits synchronized
 * state with as much references preserved as possible thus allowing easy render optimizations.
 * Because of setProps optimizations we preserve the queue of state updates and flush it only
 * when we know that the component received the state updates.
 * If for some reason messages are lost - it will request full state as a fallback.
 */
export const useOptimizedState = (
  stateWrappers: StateWrapper[],
  requestFullState: () => void,
  flushStateUpdates: () => void,
): State | undefined => {
  const { isSSR } = useEnvironment();
  const lastPatchNumber = React.useRef(0);
  const state = React.useRef<State | null>(null);

  if (stateWrappers.length === 0) {
    // controller is late
    return state.current ?? STATE_FALLBACK;
  }
  if (!isSSR) {
    flushStateUpdates();
  }
  return stateWrappers.reduce<State | undefined>((_acc, stateWrapper) => {
    switch (stateWrapper.type) {
      case 'SET': {
        // first render with initial state OR state refetch after failure
        state.current = stateWrapper.state;
        lastPatchNumber.current = 0;
        return stateWrapper.state;
      }
      case 'PATCH': {
        if (!state.current) {
          console.error('Receiving patch before init', stateWrapper);
          lastPatchNumber.current = stateWrapper.patchNumber;
          requestFullState();
          return STATE_FALLBACK;
        }
        if (lastPatchNumber.current === stateWrapper.patchNumber) {
          // new render with same state wrapper
          return state.current;
        }
        if (lastPatchNumber.current + 1 !== stateWrapper.patchNumber) {
          lastPatchNumber.current = stateWrapper.patchNumber;
          requestFullState();
          return state.current;
        }

        lastPatchNumber.current = stateWrapper.patchNumber;
        const prevState = state.current;
        state.current = applyPatch(prevState, stateWrapper.patch);

        return state.current;
      }
      default:
        throw unreachable(stateWrapper);
    }
  }, STATE_FALLBACK);
};
