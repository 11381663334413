import React from 'react';
import { ApiContext } from './api-context';
import { ReviewsProps } from '../../../common/common-types';
import { useOptimizedState } from './use-optimized-state';
import { isLoggingEnabled } from '../../../common/store/base-params/base-params-selectors';
import { useInitNavigationPrompt } from '~commons/hooks/use-navigation-prompt';
import { omit } from 'lodash';
import { useContextSelector } from '~commons/use-context-selector';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { ApiContextReady } from './api-context-types';

type ApiProviderProps = WidgetProps<ReviewsProps>;
export const ApiProvider: React.FC<React.PropsWithChildren<ApiProviderProps>> = (props) => {
  const { optimizedState, requestFullState, flushStateUpdates, ...rest } = props;
  const state = useOptimizedState(optimizedState, requestFullState, flushStateUpdates);

  return state ? (
    <ApiProviderInner {...rest} state={state} />
  ) : (
    <ApiContext.Provider value={{ type: 'HOST_ONLY', context: omit(props, 'children') }}>
      {props.children}
    </ApiContext.Provider>
  );
};

export const ApiProviderInner: React.FC<React.PropsWithChildren<ApiContextReady>> = (props) => {
  const { state, children } = props;

  useInitNavigationPrompt();

  if (typeof window !== 'undefined' && !(window as any).wixReviews) {
    // expose dev functionality
    (window as any).wixReviews = props.devToolsApi;
  }

  if (isLoggingEnabled(state)) {
    console.log('[Api provider ready props]', props);
  }

  return (
    <ApiContext.Provider
      value={{
        type: 'READY',
        context: props,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

// Prevents uninitialized context being accessed by children
export function withApiContextGuard<P>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> {
  return (props: P) => {
    const isInitialized = useContextSelector(ApiContext, (ctx) => ctx.type === 'READY', Object.is);
    if (!isInitialized) {
      return null;
    }
    return <WrappedComponent {...props} />;
  };
}
