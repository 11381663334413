import React from 'react';
import { ReviewStateSwitch } from './review-state-switch';
import {
  REVIEW_LIST,
  REVIEW_LIST_CONTAINER,
  SEE_ALL_REVIEWS_BTN,
  PENDING,
  READY,
} from '../../../common/constants/data-hooks';
import { HorizontalDivider } from '../dividers';
import { Masonry } from '../masonry/masonry';
import { Card } from '../card/card';
import { unreachable } from '~/ts-utils';
import { useApi } from '../api-provider/use-api';
import { useAppPermissionState } from '../../hooks/use-app-permissions-state';
import { ReplyStateSwitch } from './reply-state-switch';
import { RawPermission } from '../../../controller/lib/reviews-api-types';
import { LinkButton } from './link-button';
import { Pager } from './pager';
import { classes, style } from './review-list.st.css';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { ReviewsListSectionState } from '../../../common/store/reviews/reviews-types';
import { ListFilteredToEmpty } from '../../empty-states/list-filtered-to-empty';
import { ReviewState } from '../../../common/store/reviews/reviews/review-state-types';
import { AppPermissionState } from '../../../common/store/reviews/reviews-permissions';
import { DeepLinkNotFound } from '../../empty-states/deep-link-not-found';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';
import { LIST_CLASS } from '~reviews/common/services/id-utils';
import { useConfiguration } from '../configuration/use-configuration';
import { LayoutTypes } from '~commons/settings/constants/layout-options';

export const ReviewListSection: React.FC<{
  componentState: ReviewsListSectionState;
  rawPermissions: RawPermission[];
}> = ({ componentState, rawPermissions }) => {
  const { isMobile } = useEnvironment();
  const { layoutType } = useConfiguration();
  const isCardsLayout =
    !isMobile && (layoutType === LayoutTypes.BigCards || layoutType === LayoutTypes.SmallCards);
  const { fetchNextReviews, fetchPrevReviews, showAllReviews } = useApi(
    (state, actions, _host) => ({
      fetchNextReviews: () => {
        actions.biClickPaginationBtn({ action: 'next' });
        actions.fetchNextReviews();
      },
      fetchPrevReviews: () => {
        actions.biClickPaginationBtn({ action: 'previous' });
        actions.fetchPrevReviews();
      },
      showAllReviews: () => {
        actions.biClickShowAllReviewsBtn();
        actions.showAllReviews();
      },
    }),
  );

  const t = useTranslate();
  const appPermissionState = useAppPermissionState(rawPermissions);
  return (
    <section
      aria-label={t('review-list.section-area-label')}
      className={style(
        classes.root,
        {
          isPending: componentState.isLoading,
          isMobile,
        },
        LIST_CLASS,
      )}
      data-hook={classNames(REVIEW_LIST_CONTAINER, componentState.isLoading ? PENDING : READY)}
    >
      {(() => {
        switch (componentState.type) {
          case 'EMPTY':
            return null;
          case 'FILTERED_TO_EMPTY':
            return <ListFilteredToEmpty ratingFilter={componentState.ratingFilter} />;
          case 'DEEP_LINK': {
            const deepLinkState = componentState.state;
            return (
              <>
                <LinkButton
                  className={classes.showAll}
                  onClick={showAllReviews}
                  text={t('button.see-all-reviews')}
                  dataHook={SEE_ALL_REVIEWS_BTN}
                />
                {(() => {
                  switch (deepLinkState.type) {
                    case 'EXISTS': {
                      return (
                        <ul>
                          <ReviewWithReply
                            key={deepLinkState.review.reviewId}
                            reviewState={deepLinkState.review}
                            appPermissionState={appPermissionState}
                            topDivider={false}
                            isCardsLayout={isCardsLayout}
                          />
                        </ul>
                      );
                    }
                    case 'NOT_FOUND': {
                      return <DeepLinkNotFound />;
                    }
                    case 'DELETED': {
                      return null;
                    }
                    default:
                      throw unreachable(deepLinkState);
                  }
                })()}
              </>
            );
          }
          case 'ZOOMED': {
            return (
              <>
                <LinkButton
                  className={classes.showAll}
                  onClick={showAllReviews}
                  text={t('button.see-all-reviews')}
                  dataHook={SEE_ALL_REVIEWS_BTN}
                />
                {componentState.review && (
                  <ul>
                    <ReviewWithReply
                      key={componentState.review.reviewId}
                      reviewState={componentState.review}
                      appPermissionState={appPermissionState}
                      topDivider={false}
                      isCardsLayout={isCardsLayout}
                    />
                  </ul>
                )}
              </>
            );
          }
          case 'LIST': {
            const reviewsList = componentState.reviewsList.map((reviewState, index) => {
              return (
                <ReviewWithReply
                  key={reviewState.reviewId}
                  reviewState={reviewState}
                  appPermissionState={appPermissionState}
                  topDivider={index !== 0 && !isCardsLayout}
                  isCardsLayout={isCardsLayout}
                />
              );
            });

            return (
              <div data-hook={REVIEW_LIST}>
                {!isMobile && !isCardsLayout && (
                  <HorizontalDivider className={classes.topDivider} />
                )}
                {isCardsLayout ? (
                  <Masonry
                    columnCount={layoutType === LayoutTypes.BigCards.toString() ? 1 : 2}
                    columnClass={classes.masonryCardColumn}
                  >
                    {reviewsList}
                  </Masonry>
                ) : (
                  <ul>{reviewsList}</ul>
                )}
                {(componentState.hasNextPage || componentState.hasPrevPage) && (
                  <Pager
                    className={classes.pager}
                    isNextDisabled={!componentState.hasNextPage}
                    isPrevDisabled={!componentState.hasPrevPage}
                    onNext={fetchNextReviews}
                    onPrev={fetchPrevReviews}
                  />
                )}
              </div>
            );
          }
          default:
            throw unreachable(componentState);
        }
      })()}
    </section>
  );
};

const ReviewWithReply: React.FC<{
  reviewState: ReviewState;
  appPermissionState: AppPermissionState;
  topDivider: boolean;
  isCardsLayout: boolean;
}> = ({ reviewState, appPermissionState, topDivider, isCardsLayout }) => {
  const replyState = reviewState.replyState;
  const ContainerComp = isCardsLayout ? Card : 'li';
  return (
    <ContainerComp>
      {topDivider && <HorizontalDivider className={classes.divider} />}
      <ReviewStateSwitch reviewState={reviewState} appPermissionState={appPermissionState} />
      {replyState && reviewState.review.moderationStatus === 'APPROVED' && (
        <ReplyStateSwitch replyState={replyState} appPermissionState={appPermissionState} />
      )}
    </ContainerComp>
  );
};

ReviewListSection.displayName = 'ReviewListSection';
export default ReviewListSection;
