import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Dialog as TPADialog, DialogProps as TPADialogProps } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';

type DialogProps = Omit<TPADialogProps, 'isOpen' | 'aria-label' | 'aria-labelledby'> &
  ({ ['aria-label']: string } | { ['aria-labelledby']: string });

export const Dialog: React.FC<DialogProps> = (props) => {
  const { isMobile } = useEnvironment();
  React.useEffect(() => {
    if (isMobile) {
      const overflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = overflow;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const t = useTranslate();

  return <TPADialog {...props} isOpen closeButtonAriaLabel={t('close-button.aria-label')} />;
};
