import React from 'react';
import classNames from 'classnames';
import { ReplyProps } from './reply-props';
import {
  REPLY_CONTENT,
  ERROR,
  LIST_ITEM,
  PENDING,
  REPLY,
} from '../../../common/constants/data-hooks';
import ListItemContainer from '../list-item-container/list-item-container';
import { classes } from './reply-desktop.st.css';
import { SiteOwnerInfo } from '../user-info/site-owner-info';
import { FormattedText } from '../_common/formatted-text/formatted-text';

const ReplyDesktop: React.FC<ReplyProps> = ({
  content,
  createdAt,
  isPending = false,
  isError = false,
  actionsMenu,
  id,
}) => (
  <ListItemContainer
    id={id}
    dataHook={classNames(LIST_ITEM, isPending && PENDING, REPLY, isError && ERROR)}
    isPending={isPending}
    isReply={true}
    className={classes.container}
  >
    <div className={classes.header}>
      <SiteOwnerInfo time={createdAt} />
      <div className={classes.rightSide}>{actionsMenu}</div>
    </div>
    <div data-hook={REPLY_CONTENT} className={classes.main}>
      <FormattedText text={content || ''} />
    </div>
  </ListItemContainer>
);

export default ReplyDesktop;
