export const LIST_CLASS = 'wixReviews-list';
export const ROOT_FORM_CLASS = 'wixReviews-rootForm';
export const makeRootId = (namespace: string, resourceId: string) =>
  `wix-reviews-${namespace}-${resourceId}`;

export const makeListSelector = (namespace: string, resourceId: string) => {
  return `#${makeRootId(namespace, resourceId)} .${LIST_CLASS}`;
};

export const makeRootSelector = (namespace: string, resourceId: string) => {
  return `#${makeRootId(namespace, resourceId)}`;
};

export const makeRootFormSelector = (namespace: string, resourceId: string) => {
  return `#${makeRootId(namespace, resourceId)} .${ROOT_FORM_CLASS}`;
};

export const makeReviewHtmlId = (id: string) => {
  // Prefix 'C-' is added to conform to html id standard
  return `C-${id}`;
};

export const makeReviewSelector = (reviewId: string) => {
  return `#${makeReviewHtmlId(reviewId)}`;
};

export const getReplyHtmlId = (reviewId: string) => {
  return `reply-for-${reviewId}`;
};

export const makeReplySelector = (reviewId: string) => {
  return `#${getReplyHtmlId(reviewId)}`;
};
