import React from 'react';

export const CopyLinkIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <defs>
      <path
        id="o97haut5cb"
        d="M13.095 10.287l.137.142c1.505 1.63 1.678 4.01.282 5.406l-2.093 2.093-.137.13c-1.472 1.35-3.537 1.325-5.075-.213-1.59-1.59-1.593-3.774-.12-5.248l1.685-1.683.942.942-1.684 1.683-.106.112c-.845.944-.804 2.223.225 3.252 1.06 1.061 2.364 1.047 3.328.083l2.093-2.093.09-.097c.773-.895.595-2.463-.509-3.567l.942-.942zm-.38-4.31c1.473-1.348 3.538-1.324 5.076.214 1.59 1.59 1.593 3.774.12 5.248l-1.685 1.684-.942-.942 1.684-1.684.106-.112c.845-.944.804-2.223-.225-3.252-1.06-1.06-2.364-1.047-3.328-.083l-2.093 2.093-.09.097c-.773.895-.595 2.463.509 3.567l-.942.942-.137-.142c-1.505-1.63-1.678-4.01-.282-5.406l2.093-2.093z"
      />
    </defs>
    <g transform="translate(-785 -169) translate(773 149) translate(12 12) translate(0 8)">
      <use xlinkHref="#o97haut5cb" />
    </g>
  </svg>
);
