import React from 'react';
import classNames from 'classnames';
import { Button, ButtonPriority as PRIORITY } from 'wix-ui-tpa/cssVars';
import { classes } from './link-button.st.css';
import TextExtended from '../text-extended/text-extended';

export const LinkButton: React.FC<{
  text: string;
  onClick?: () => void;
  className?: string;
  dataHook: string;
  disabled?: boolean;
  PrefixIcon?: React.ComponentType<{ className?: string }>;
  SuffixIcon?: React.ComponentType<{ className?: string }>;
}> = ({ text, onClick, className, dataHook, disabled, PrefixIcon, SuffixIcon }) => {
  return (
    <Button
      className={classNames(classes.buttonRoot, className)}
      onClick={onClick && onClick}
      priority={PRIORITY.secondary}
      data-hook={dataHook}
      disabled={disabled}
      upgrade
    >
      {PrefixIcon ? <PrefixIcon className={classes.prefixIcon} /> : null}
      <TextExtended className={classes.text} fontMapping="custom" colorMapping="custom">
        {text}
      </TextExtended>
      {SuffixIcon ? <SuffixIcon className={classes.suffixIcon} /> : null}
    </Button>
  );
};
