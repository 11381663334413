import React, { useEffect } from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { HorizontalDivider } from '../components/dividers';
import TextExtended from '../components/text-extended/text-extended';
import { classes, style } from './list-filtered-to-empty.st.css';
import { EMPTY_STATE_FILTERED_TO_EMPTY } from '../../common/constants/data-hooks';
import { useApi } from '../components/api-provider/use-api';

export const ListFilteredToEmpty: React.FC<{ ratingFilter: number }> = ({ ratingFilter }) => {
  const t = useTranslate();
  const { biShowFilteredToZeroEmptyState } = useApi((state, actions) => ({
    biShowFilteredToZeroEmptyState: actions.biShowFilteredToZeroEmptyState,
  }));
  useEffect(() => {
    biShowFilteredToZeroEmptyState(ratingFilter);
  }, [biShowFilteredToZeroEmptyState, ratingFilter]);
  return (
    <div className={style(classes.root)} data-hook={EMPTY_STATE_FILTERED_TO_EMPTY}>
      <HorizontalDivider />
      <div className={style(classes.textContainer)}>
        <TextExtended>{t('empty-state.list-filtered-to-empty', { ratingFilter })}</TextExtended>
      </div>
    </div>
  );
};
