import { State } from '../store-types';
import {
  ComponentState,
  ReviewsStateReady,
  ReviewsState,
  TopSectionState,
  ReviewsListSectionState,
} from './reviews-types';
import { NewReviewFormState, ReviewsListState } from './reviews/review-state-types';
import { assertDefined, isDefined, unreachable } from '~/ts-utils';
import { hasNextChunk, hasPrevChunk } from './paging/reviews-paging-selectors';
import { getReviewsSummary } from './ratings-selectors';
import { getReviewState } from './reviews/reviews-state-selectors';
import { Review } from '../../../controller/lib/reviews-api-types';

export const getAppState = (state: ReviewsState): ComponentState => {
  switch (state.type) {
    case 'INITIAL':
    case 'PENDING': {
      return { type: 'PENDING' };
    }
    case 'ERROR': {
      return { type: 'ERROR', error: state.error };
    }
    case 'READY': {
      return {
        type: 'READY',
        topSection: getTopSectionState(state),
        listSection: getListSectionsState(state),
        rawPermissions: state.permissions,
        config: state.config,
        highlight: state.effects.highlight,
      };
    }
    default: {
      throw unreachable(state);
    }
  }
};

export const getListSectionsState = (state: ReviewsStateReady): ReviewsListSectionState => {
  const reviewsSummary = getReviewsSummary(state);

  // The recursion is here only if the list is in loading state - then we return all the info of previous state to show to user
  const resolveState = (
    reviewListState: ReviewsListState,
    isLoading: boolean,
  ): ReviewsListSectionState => {
    if (!reviewsSummary.totalReviews && !getCurrentUserReview(state)) {
      return { type: 'EMPTY', isLoading };
    }

    if (
      reviewListState.type === 'READY' &&
      reviewListState.ratingFilter &&
      reviewsSummary.ratingsBreakdown[reviewListState.ratingFilter] === 0 &&
      !reviewListState.prependCurrentUserReview
    ) {
      return {
        type: 'FILTERED_TO_EMPTY',
        ratingFilter: reviewListState.ratingFilter,
        isLoading,
      };
    }

    if (reviewListState.type === 'READY') {
      const userReview = state.userReview;
      const reviewsToPrepend =
        reviewListState.prependCurrentUserReview && userReview.type === 'CREATED'
          ? [userReview.review.reviewId]
          : [];
      const reviewList = [...reviewsToPrepend, ...reviewListState.chunk.reviewIds]
        .map((id) => getReviewState(id, state))
        .filter(isDefined);
      return {
        type: 'LIST',
        reviewsList: reviewList,
        hasNextPage: hasNextChunk(state.reviewList),
        hasPrevPage: hasPrevChunk(state.reviewList),
        isLoading,
      };
    }
    if (reviewListState.type === 'ONLY_USER_REVIEW') {
      return {
        type: 'ZOOMED',
        isLoading,
        // There might be no review if the user deletes in while in this mode
        review: state.userReview.type === 'CREATED' ? state.userReview.review : undefined,
      };
    }
    if (reviewListState.type === 'DEEP_LINK') {
      const deepLinkState = reviewListState.state;
      return {
        type: 'DEEP_LINK',
        isLoading,
        state: (() => {
          switch (deepLinkState) {
            case 'EXISTS':
              const review = getReviewState(reviewListState.reviewId, state);
              assertDefined(review);
              return { type: 'EXISTS' as const, review };
            case 'NOT_FOUND':
              return { type: 'NOT_FOUND' as const };
            case 'DELETED':
              return { type: 'DELETED' as const };
            default:
              throw unreachable(deepLinkState);
          }
        })(),
      };
    }
    return resolveState(reviewListState.prevState, true);
  };

  return resolveState(state.reviewList, false);
};

const getTopSectionState = (state: ReviewsStateReady): TopSectionState => {
  const reviewsSummary = getReviewsSummary(state);
  const reviewForm: NewReviewFormState =
    state.userReview.type === 'NOT_CREATED' ? state.userReview.form : { type: 'HIDDEN' };
  if (!reviewsSummary.totalReviews) {
    return state.userReview.type === 'CREATED'
      ? {
          type: 'UNPUBLISHED_REVIEW',
          reviewForm,
          callToAction: 'VIEW_MY_REVIEW',
        }
      : { type: 'EMPTY', reviewForm };
  }
  return {
    type: 'FULL_LIST',
    reviewsSummary,
    reviewForm,
    callToAction: state.userReview.type === 'CREATED' ? 'VIEW_MY_REVIEW' : 'CREATE_REVIEW',
    ordering: state.config.pagination.ordering,
    ratingFilter: state.config.pagination.ratingFilter,
  };
};

export const getToast = (state: State) => {
  const resourceState = state.reviews;
  if (resourceState.type !== 'READY') {
    return undefined;
  }
  return resourceState.effects.toast;
};

export const getPrompt = (state: State) => {
  const resourceState = state.reviews;
  if (resourceState.type !== 'READY') {
    return undefined;
  }
  return resourceState.effects.prompt;
};

export const getCurrentUserReview = (state: ReviewsState): Review | undefined => {
  return state.type === 'READY' && state.userReview.type === 'CREATED'
    ? state.userReview.review.review
    : undefined;
};
