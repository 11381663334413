import forDevice from '../../../hoc/for-device';
import { HeaderMobile } from './header-mobile';
import { HeaderDesktop } from './header-desktop';
import { ReviewsSummary } from '~reviews/common/common-types';
import { CallToActionButtonState } from '../call-to-action-button';

export const Header = forDevice(HeaderMobile, HeaderDesktop);
export type HeaderPropType = {
  reviewsSummary: ReviewsSummary;
  callToAction?: CallToActionButtonState;
};
