import React from 'react';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';
import { classes } from './reviews-list-header-mobile.st.css';
import { ArrowDownIcon } from '../icons/arrow-icon';
import { HorizontalDivider } from '../dividers';
import { useModal, FilterSortModal } from '../modals';
import { ReviewsListHeaderProps } from '.';
import { HEADER_TITLE, SORT_FILTER_BTN } from '../../../common/constants/data-hooks';
import TextExtended from '../text-extended/text-extended';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../configuration/use-configuration';
import { Ordering } from '~reviews/controller/lib/reviews-api-types';
import {
  getFilterOptions,
  getOrderingOptions,
  stringifyRatingFilterValue,
} from '~reviews/common/services/ordering-and-filter-utils';
import { useApi } from '../api-provider/use-api';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '~reviews/stylesParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ReviewsListHeaderMobile: React.FC<ReviewsListHeaderProps> = React.memo(
  ({ className, selectedFilter, selectedOrdering, reviewCount }) => {
    const t = useTranslate();
    const showModal = useModal();
    const { isCssPerBreakpoint } = useEnvironment();
    const styles = useStyles();
    const configuration = useConfiguration();
    const { isSortEnabled, isFilterEnabled } = isCssPerBreakpoint
      ? {
          isSortEnabled: styles.get(stylesParams.isSortEnabled),
          isFilterEnabled: styles.get(stylesParams.isFilterEnabled),
        }
      : configuration;

    const { biOpenSorting, biChangeSorting, biChangeFiltering, changeOrderingAndFilter } = useApi(
      (state, actions) => ({
        changeOrdering: actions.changeOrdering,
        changeRatingFilter: actions.changeRatingFilter,
        biOpenSorting: actions.biOpenSorting,
        biOpenFiltering: actions.biOpenFiltering,
        biChangeSorting: actions.biChangeSorting,
        biChangeFiltering: actions.biChangeFiltering,
        changeOrderingAndFilter: actions.changeOrderingAndFilter,
      }),
    );

    const onOrderingAndFilterChange = React.useCallback(
      ({ ratingFilter, ordering }: { ordering: Ordering; ratingFilter: number | undefined }) => {
        ordering !== selectedOrdering && biChangeSorting({ ordering });
        ratingFilter !== selectedFilter &&
          biChangeFiltering({ rating: ratingFilter?.toString() ?? '', origin: 'dropdown' });
        changeOrderingAndFilter({
          ordering,
          ratingFilter,
        });
      },
      [
        selectedOrdering,
        biChangeSorting,
        selectedFilter,
        biChangeFiltering,
        changeOrderingAndFilter,
      ],
    );

    const text = React.useMemo(
      () =>
        [
          isFilterEnabled &&
            getFilterOptions(t).find((o) => o.id === stringifyRatingFilterValue(selectedFilter))
              ?.value,
          isSortEnabled &&
            getOrderingOptions(t, selectedFilter).find((o) => o.id === selectedOrdering)?.value,
        ]
          .filter(Boolean)
          .join(', '),
      [isFilterEnabled, t, isSortEnabled, selectedFilter, selectedOrdering],
    );

    return (
      <div className={className}>
        {(isFilterEnabled || isSortEnabled) && (
          <React.Fragment>
            <HorizontalDivider />
            <TextButton
              className={classes.button}
              priority={TextButtonPriority.secondary}
              onClick={() => {
                showModal((toggle) => (
                  <FilterSortModal
                    onClose={toggle}
                    onChange={onOrderingAndFilterChange}
                    selectedFilter={selectedFilter}
                    selectedOrdering={selectedOrdering}
                  />
                ));
                // It was decided to emit open sort event from mobile.
                biOpenSorting();
              }}
              suffixIcon={<ArrowDownIcon />}
              data-hook={SORT_FILTER_BTN}
            >
              <TextExtended fontMapping="headerControl" colorMapping="headerControl">
                {text}
              </TextExtended>
            </TextButton>
          </React.Fragment>
        )}
        <HorizontalDivider />
        <div data-hook={HEADER_TITLE} className={classes.title}>
          <TextExtended fontMapping="headerControl" colorMapping="headerControl">
            {t('filters-row.all-reviews', { count: reviewCount })}
          </TextExtended>
        </div>
      </div>
    );
  },
);

ReviewsListHeaderMobile.displayName = 'ReviewsListHeaderMobile';

export default ReviewsListHeaderMobile;
