import { uniqueId } from 'lodash';
import React from 'react';
import { DEFAULT_CONFIG, highlightSelector } from '~commons/services/highlight-utils';
import { MediaStateError } from './types';

const HIGHLIGHT_CONFIG = {
  ...DEFAULT_CONFIG,
  shouldHighlightBackground: false,
};

export const useHighlightMedia = (errors: MediaStateError[]) => {
  const [errorCount, setErrorCount] = React.useState(0);
  const errorSectionId = `media-error-${React.useMemo(() => uniqueId(), [])}`;
  React.useEffect(() => {
    if (errors.length > errorCount) {
      highlightSelector(errorSectionId, HIGHLIGHT_CONFIG);
    }
    setErrorCount(errors.length);
  }, [errors.length, errorCount, errorSectionId]);
  return { errorSectionId };
};
