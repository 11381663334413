import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { isOfType } from '~/ts-utils';
import { highlightNavigationPromptTarget } from '~reviews/Widget/components/app/use-highlight';
import { ResolveCancelFlow } from '~reviews/Widget/hooks/use-cancel-flow';

type PromptHandler = ResolveCancelFlow;
let promptHandlers: PromptHandler[] = [];

export const enableNavigationPrompt = (cb: PromptHandler) => {
  promptHandlers.push(cb);

  return () => {
    promptHandlers = promptHandlers.filter((x) => x !== cb);
  };
};

export const useNavigationPrompt = ({
  resolveCancelFlow,
  formIsMounted,
}: {
  resolveCancelFlow: ResolveCancelFlow;
  formIsMounted?: boolean;
}) => {
  React.useEffect(() => {
    if (formIsMounted) {
      return enableNavigationPrompt(resolveCancelFlow);
    }
  }, [formIsMounted, resolveCancelFlow]);
};

export const useInitNavigationPrompt = () => {
  const { isMobile } = useEnvironment();
  // Don't allow our redirect to retriger cancels
  let internalNavigate = false;

  const handleDocumentClick = (event: MouseEvent) => {
    if (internalNavigate) {
      return;
    }

    let element: HTMLElement | null = event.target as HTMLElement;
    while (element?.tagName.toLowerCase() !== 'a') {
      element = element.parentElement;
      if (!element) {
        return;
      }
    }

    if (element.getAttribute('target') === '_blank') {
      return;
    }

    const pauseNavigation = () => {
      event.preventDefault();
      event.stopImmediatePropagation();
    };
    const resumeNavigation = () => {
      internalNavigate = true;
      (event.target as HTMLElement).click();
    };

    const cancellationResults = promptHandlers.map((handler) => handler());

    cancellationResults.filter(isOfType('SILENT_CANCEL')).forEach((r) => r.cleanup());

    const cancellationsWithPrompts = cancellationResults.filter(isOfType('PROMPT'));
    if (cancellationsWithPrompts.length === 0) {
      return;
    }
    pauseNavigation();

    const first = cancellationsWithPrompts[0];
    if (first.highlightSelector) {
      highlightNavigationPromptTarget(first.highlightSelector, isMobile);
    }

    first.prompt().then((promptResult) => {
      if (promptResult === 'CANCEL_DISCARD') {
        return;
      } else {
        cancellationsWithPrompts.forEach((p) => p.onConfirm());
        resumeNavigation();
      }
    });
  };

  const handleUnload = (event: BeforeUnloadEvent) => {
    const cancellationResults = promptHandlers.map((handler) => handler());
    const cancellationsWithPrompts = cancellationResults.filter(isOfType('PROMPT'));
    if (cancellationsWithPrompts.length === 0) {
      return;
    }
    // Cancel the event
    event.preventDefault();
    // Chrome requires returnValue to be set
    event.returnValue = '';

    const first = cancellationsWithPrompts[0];
    if (first.highlightSelector) {
      highlightNavigationPromptTarget(first.highlightSelector, isMobile);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.document.addEventListener('click', handleDocumentClick, true);
      window.addEventListener('beforeunload', handleUnload);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.document.removeEventListener('click', handleDocumentClick, true);
        window.removeEventListener('beforeunload', handleUnload);
      }
    };
  }, [isMobile]);
};
