import React from 'react';
import { withErrorBoundary } from './with-error';

const withLazy = <T extends {}>({
  importFn,
  loader: Loader,
  ssrOnlyLoader,
  errorHandling,
}: {
  importFn: () => Promise<{ default: React.ComponentType<T> }>;
  loader?: React.ComponentType<{}>;
  ssrOnlyLoader?: boolean;
  errorHandling:
    | {
        type: 'PROPAGATE';
      }
    | { type: 'HANDLE'; fallbackComponent?: React.ComponentType<any> };
}): React.ComponentType<T> => {
  const Component: any = React.lazy(importFn);
  const isSSR = typeof window === 'undefined';

  const WrappedComponent: React.ComponentType<T> = (props) => {
    if (isSSR) {
      return Loader ? <Loader {...props} /> : null;
    }

    return (
      <React.Suspense fallback={Loader && !ssrOnlyLoader ? <Loader {...props} /> : null}>
        <Component {...props} />
      </React.Suspense>
    );
  };

  return errorHandling.type === 'PROPAGATE'
    ? WrappedComponent
    : withErrorBoundary(WrappedComponent, errorHandling.fallbackComponent);
};

export default withLazy;
