import React from 'react';
import { classes } from './modal-header.st.css';
import TextExtended from '../../text-extended/text-extended';

type ModalHeaderProps = {
  dataHook?: string;
  id: string;
};

const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({
  id,
  children,
  dataHook,
}) => (
  <h2 className={classes.root} id={id}>
    <TextExtended
      className={classes.title}
      data-hook={dataHook}
      fontMapping="siteTitle"
      size={40}
      colorMapping="black"
    >
      {children}
    </TextExtended>
  </h2>
);

export default ModalHeader;
