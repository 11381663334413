import {
  ContactField,
  FormSettings,
  MediaField,
  TextField,
} from '~commons/components/review-form/types';
import { AllowedMediaTypes } from '~commons/components/review-form/media-input/types';
import { Configuration } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';
import { useCommonsTranslate } from '~commons/hooks/use-commons-translate';

type ContactConfiguration = {
  name: ContactField;
  email: ContactField;
};

export const useFormSettings = ({
  configuration,
  contactSettings,
}: {
  configuration: Configuration;
  contactSettings: ContactConfiguration;
}): FormSettings => {
  const t = useCommonsTranslate();
  const { name, email } = contactSettings;
  const { title, body, media, customFieldNames } = configuration;

  const buildTitleField = (): TextField => {
    const { maxLength: limit = 0, required = false } = title || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          label: customFieldNames?.title || t('field-label.title.default'),
          required,
          limit,
        }
      : {
          enabled: false,
        };
  };

  const buildBodyField = (): TextField => {
    const { maxLength: limit = 0, required = false } = body || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          label: customFieldNames?.body || t('field-label.body.default'),
          required,
          limit,
        }
      : {
          enabled: false,
        };
  };

  const buildMediaField = (): MediaField => {
    const { limit = 0, required = false } = media || {};
    return limit !== null && limit > 0
      ? {
          enabled: true,
          allowedMedia: (customFieldNames?.media ?? 'all') as AllowedMediaTypes,
          required,
          limit,
        }
      : { enabled: false };
  };

  return {
    name,
    email,
    title: buildTitleField(),
    body: buildBodyField(),
    media: buildMediaField(),
    publishButtonText: customFieldNames?.publish || t('review-form.publish.default'),
    ctaButtonText: customFieldNames?.addReview || t('top-section.cta.default'),
    ratingLabel: customFieldNames?.rating || t('field-label.star-rating.default'),
  };
};
