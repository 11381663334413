export const isIos = () =>
  typeof navigator !== 'undefined' && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

/* hack to trigger opening of software keyboard on Ios.
   Unlike on Android, simply focusing on input doesn't open the keyboard,
   on Ios there must be user initiated input and then keyboard opens up if input is focused during a short timeframe.
   so this helper will work if used in an user event handler.
   focus then programatically must be set on target element, otherwise the keyboard will close
   has to be used in the click handler to work
*/

export const triggerIosKeyboard = () => {
  if (!isIos() || typeof document === 'undefined') {
    return;
  }
  const i = document.createElement('input');
  const id = 'fake-input';
  i.setAttribute('id', id);
  i.style.fontSize = '16px';
  i.style.position = 'fixed';
  i.style.width = '1px';
  i.style.height = '1px';
  i.style.top = '0';
  i.style.opacity = '0';
  i.style.zIndex = '99999';

  !document.getElementById(id) && document.body.appendChild(i);
  const inputEl = document.getElementById(id);
  if (inputEl) {
    inputEl.focus();
    setTimeout(() => document.body.removeChild(inputEl), 1000);
  }
};
