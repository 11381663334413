import { SmallStars } from '@wix/ftsystem';
import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { st, classes } from './rating-in-reviews.st.css';
import VerifiedBadge from '../review/verified-badge';

export const RatingsInReview: React.FC<{
  isBW?: boolean;
  value: number;
  dataHook?: string;
  verified: boolean;
}> = ({ isBW = false, value, dataHook, verified }) => {
  const t = useTranslate();
  return (
    <div className={classes.ratingsWrapper}>
      <SmallStars
        dataHook={dataHook}
        a11yLabel={t('review.rating-aria-label', { rating: value })}
        rating={value}
        className={st(classes.root, { isBW })}
      />
      <VerifiedBadge verified={verified} isBW={isBW} />
    </div>
  );
};
