import {
  HighlightConfig,
  isOffscreen,
  HIGHLIGHT_CLASS,
  BG_TRANSITION_CLASS,
} from './highlight-utils';

export const highlightElement = (element: HTMLElement, highlightConfig: HighlightConfig) => {
  if (highlightConfig.shouldFocus) {
    const top = window.scrollY;
    // preventScroll is not yet fully supported
    element.focus({ preventScroll: true });
    window.scroll({ top });
  }
  if (highlightConfig.shouldScroll) {
    // Scrolling is skipped for desktop 'CRUD_SUCCESS' highlights
    const rect = element.getBoundingClientRect();
    if (isOffscreen(rect)) {
      const options = {
        top: rect.top - document.documentElement.clientHeight / 4,
        left: 0,
        behavior: highlightConfig.smoothScroll ? ('smooth' as const) : ('auto' as const),
      };
      window.scrollBy(options);
    }
  }

  if (
    highlightConfig.shouldHighlightBackground &&
    !element.classList.contains(HIGHLIGHT_CLASS) &&
    !element.classList.contains(BG_TRANSITION_CLASS)
  ) {
    element.classList.add(HIGHLIGHT_CLASS, BG_TRANSITION_CLASS);
    setTimeout(() => {
      element.classList.remove(HIGHLIGHT_CLASS);
      setTimeout(() => element.classList.remove(BG_TRANSITION_CLASS), 500);
    }, highlightConfig.highlightDuration);
  }
};
