export const REVIEW_FORM = 'review-form';
export const PENDING = 'review-form-pending';
export const NAME_FIELD = 'name-field';
export const EMAIL_FIELD = 'email-field';
export const RATING_FIELD = 'rating-field';
export const TITLE_FIELD = 'title-field';
export const BODY_FIELD = 'body-field';
export const MEDIA_FIELD = 'media-field';
export const FORM_ERROR = 'form-error';
export const FORM_ERROR_CTA = 'form-error-cta';
export const SUBMIT_CANCEL_BUTTONS = 'submit-cancel-buttons';
