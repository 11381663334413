import React from 'react';
import TextExtended from '../../text-extended/text-extended';
import { classes, st } from './formatted-text.st.css';

export const FormattedText = React.forwardRef<
  HTMLDivElement,
  {
    className?: string;
    text: string;
    dataHook?: string;
    style?: React.CSSProperties;
    isBw?: boolean;
  }
>(({ className, text, dataHook, style, isBw }, ref) => {
  return (
    <TextExtended dataHook={dataHook} colorMapping={isBw ? 'black' : 'content'}>
      <div className={st(classes.root, className)} style={style} ref={ref}>
        {text.split('\n').map((p, i) => (
          <p className={classes.paragraph} key={i}>
            {p.trim() === '' ? <br /> : p}
          </p>
        ))}
      </div>
    </TextExtended>
  );
});
