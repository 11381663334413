import React from 'react';
import classNames from 'classnames';
import { ModalContext } from './use-modal';
import { isShallowEqual } from '~commons/services/is-shallow-equal';
import { useContextSelector } from '~commons/use-context-selector';
import styles from './modal-root.scss';
import { useApi } from '~reviews/Widget/components/api-provider/use-api';
import { getPrompt } from '~reviews/common/store/reviews/reviews-selectors';
import { PendingReviewModal } from './pending-review-modal/pending-review-modal';
import { highlightModerationPromptTarget } from '~reviews/Widget/components/app/use-highlight';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { makeReviewHtmlId } from '~reviews/common/services/id-utils';
import reviewStyles from '../../styles/review-styles.scss';
import { useRootRef } from '../root-ref-provider/use-root-ref';
import ReactDOM from 'react-dom';

const ModalRoot = () => {
  const { isMobile } = useEnvironment();
  const { prompt } = useApi((state) => ({
    prompt: getPrompt(state),
  }));
  const rootRef = useRootRef();
  const [tpaWrapperClass, setTpaWrapperClass] = React.useState('');
  React.useEffect(() => {
    setTpaWrapperClass(rootRef?.parentElement?.className ?? '');
  }, [rootRef]);

  const [hiddenModalTimestamp, setHiddenModalTimestamp] = React.useState<number | undefined>(
    undefined,
  );
  const modals = {
    ...useContextSelector(ModalContext, (ctx) => ctx.modals, isShallowEqual),
    ...(prompt && hiddenModalTimestamp !== prompt.timestamp
      ? {
          [prompt.id]: {
            modal: (() => {
              switch (prompt.type) {
                case 'MODERATION_PROMPT':
                  return (
                    <PendingReviewModal
                      reviewId={prompt.id}
                      onClose={() => {
                        highlightModerationPromptTarget(makeReviewHtmlId(prompt.id), isMobile);
                        setHiddenModalTimestamp(prompt.timestamp);
                      }}
                    />
                  );
              }
            })(),
            prevFocusOwner: null,
          },
        }
      : {}),
  };
  const modalKeys = Object.keys(modals);

  const getSiteContainer = () => document.getElementById('SITE_CONTAINER') ?? document.body;
  return modalKeys.length
    ? ReactDOM.createPortal(
        <div className={classNames(styles.root, reviewStyles.reviews, tpaWrapperClass)}>
          {modalKeys.map((id) => (
            <React.Fragment key={id}>
              <React.Suspense fallback={null}>{modals[id].modal}</React.Suspense>
            </React.Fragment>
          ))}
        </div>,
        getSiteContainer(),
      )
    : null;
};

export default ModalRoot;
