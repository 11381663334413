import forDevice from '../../hoc/for-device';
import ReviewsListHeaderMobile from './reviews-list-header-mobile';
import ReviewsListHeaderDesktop from './reviews-list-header-desktop';
import { Ordering } from '~reviews/controller/lib/reviews-api-types';

export const ReviewsListHeader = forDevice(ReviewsListHeaderMobile, ReviewsListHeaderDesktop);

export type ReviewsListHeaderProps = {
  reviewCount: number;
  selectedOrdering: Ordering;
  selectedFilter: number | undefined;
  className?: string;
};
