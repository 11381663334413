import React from 'react';
import classNames from 'classnames';
import { ReplyProps } from './reply-props';
import { classes } from './reply-mobile.st.css';
import {
  REPLY_CONTENT,
  ERROR,
  LIST_ITEM,
  PENDING,
  REPLY,
} from '../../../common/constants/data-hooks';
import ListItemContainer from '../list-item-container/list-item-container';
import TextExtended from '../text-extended/text-extended';
import { SiteOwnerInfo } from '../user-info/site-owner-info';

const ReplyMobile: React.FC<ReplyProps> = ({
  createdAt,
  content,
  isPending = false,
  actionsMenu,
  id,
  isError,
}) => (
  <ListItemContainer
    id={id}
    dataHook={classNames(LIST_ITEM, isPending && PENDING, REPLY, isError && ERROR)}
    className={classes.container}
    isPending={isPending}
    isReply
  >
    <div className={classes.header}>
      <div className={classes.leftSide}>
        <SiteOwnerInfo time={createdAt} />
      </div>
      <div className={classes.rightSide}>{actionsMenu}</div>
    </div>
    <div data-hook={REPLY_CONTENT} className={classes.main}>
      <TextExtended>{content}</TextExtended>
    </div>
  </ListItemContainer>
);

export default ReplyMobile;
