import { lazy } from 'react';

export const DeleteCommentConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "wr-delete-comment-conformation-modal" */ './delete-comment-confirmation-modal/delete-comment-confirmation-modal-component'
    ),
);

export const DiscardConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "wr-discard-comment-conformation-modal" */ './discard-confirmation-modal/discard-confirmation-modal-component'
    ),
);

export const DiscardChangesConfirmationModal = lazy(
  () =>
    import(
      /* webpackChunkName: "wr-discard-changes-conformation-modal" */ './discard-changes-confirmation-modal/discard-changes-confirmation-modal-component'
    ),
);

export const ReviewsActionSheetModal = lazy(
  () =>
    import(
      /* webpackChunkName: "reviews-action-sheet-modal" */ './reviews-action-sheet-modal/review-action-sheet-modal-component'
    ),
);

export const FilterSortModal = lazy(
  () =>
    import(
      /* webpackChunkName: "filter-sort-modal" */ './filter-sort-modal/filter-sort-modal-component'
    ),
);

export { useModal, ModalProvider } from './use-modal';
