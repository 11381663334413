import React from 'react';
import {
  Toast,
  ToastPlacement as TOAST_PLACEMENT,
  ToastSkin as TOAST_SKIN,
} from 'wix-ui-tpa/cssVars';
import { TOAST } from '../../../common/constants/data-hooks';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useApi } from '../api-provider/use-api';
import { classes } from './toast-root.st.css';
import { getToast } from '../../../common/store/reviews/reviews-selectors';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const ToastRoot = React.memo(() => {
  const { isMobile } = useEnvironment();
  const { toastConfig } = useApi((state) => ({
    toastConfig: getToast(state),
  }));
  const t = useTranslate();

  const [hiddenToastTimestamp, setHiddenToastTimestamp] = React.useState<number | undefined>(
    undefined,
  );

  const hideToast = () => {
    setHiddenToastTimestamp(toastConfig?.timestamp);
  };

  React.useEffect(() => {
    if (toastConfig) {
      const timeoutId = setTimeout(hideToast, 5000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastConfig?.timestamp]);

  if (!toastConfig || hiddenToastTimestamp === toastConfig?.timestamp) {
    return null;
  }

  const { message, skin, isHidden } = toastConfig;
  const toast = (
    <Toast
      data-hook={TOAST}
      skin={TOAST_SKIN[skin]}
      onClose={hideToast}
      className={classes.toast}
      placement={isMobile && !isHidden ? TOAST_PLACEMENT.bottomFullWidth : TOAST_PLACEMENT.inline}
      shouldShowCloseButton
    >
      {message.type === 'CUSTOM' ? message.message : t(message.key, message.keyValue)}
    </Toast>
  );

  if (toastConfig.isHidden) {
    return <div className={classes.hiddenContainer}>{toast}</div>;
  }

  return isMobile ? toast : <div className={classes.container}>{toast}</div>;
});

export default ToastRoot;
