import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';

const forDevice =
  <T extends {}>(
    MobileComponent: React.ComponentType<T>,
    DesktopComponent: React.ComponentType<T>,
    { forceDesktopWhenInlineMobile }: { forceDesktopWhenInlineMobile?: boolean } = {},
  ) =>
  ({ ...props }: T) => {
    const { isMobile } = useEnvironment();
    const Component = !isMobile ? DesktopComponent : MobileComponent;
    return <Component {...props} />;
  };

export default forDevice;
