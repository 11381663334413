import React from 'react';
import styles from './video-play-icon.scss';
import { PLAY_TRIANGLE } from '~reviews/common/constants/data-hooks';

export const VideoPlayIcon = ({ onVideoPlay }: { onVideoPlay: () => void }) => {
  return (
    <div className={styles.iconContainer}>
      <i data-hook={PLAY_TRIANGLE} className={styles.playTriangle}>
        <svg viewBox="0 0 60 60" fill="currentColor" width="60" height="60">
          <path d="M41.5,30l-17,10V20L41.5,30z"></path>
        </svg>
      </i>
      <i className={styles.playBackground} onClick={onVideoPlay}>
        <svg
          viewBox="0 0 60 60"
          fill="currentColor"
          width="60"
          height="60"
          className={styles.svgContainer}
        >
          <path
            d="M30,0c16.6,0,30,13.4,30,30S46.6,60,30,60C13.4,60,0,46.6,0,30S13.4,0,30,0z"
            fill-rule="evenodd"
            clip-rule="evenodd"
          ></path>
        </svg>
      </i>
    </div>
  );
};
