import { PlatformImage, PlatformVideo } from '~commons/types';
import {
  getImageUrl,
  getVideoPosterUrl,
  getVideoUrl,
} from '~commons/components/review-form/get-media-url';
import { GalleryImageItem, GalleryVideoItem, VideoQuality } from './gallery-types';

// from https://github.com/wix-private/photography-pro-gallery/blob/36c109b62113e5efec21a0b5a966579644baf602/packages/pro-gallery-statics/src/_domain/settings-item.js#L85
export const wixVideoToGallery = (
  videoData: PlatformVideo,
  container: { width: number; height: number },
): GalleryVideoItem => {
  const qualities = (videoData.resolutions || []).map((r) => {
    return {
      height: r.height!,
      width: r.width!,
      quality: r.height + 'p',
      formats: [r.format!],
    };
  });

  const posters = (videoData.resolutions || []).map((resolution) => {
    const poster = resolution.poster!;
    return {
      width: poster.width!,
      height: poster.height!,
      url: getVideoPosterUrl(poster, { maxWidth: container.width, maxHeight: container.height }),
    };
  });

  const resolution = getHighestMp4Resolution(qualities);

  // const mediaUrl = videoData.fileBaseUrl.replace('video/', '');
  return {
    itemId: videoData.id!,
    mediaUrl: getVideoUrl(videoData.resolutions![0].url!),
    metaData: {
      name: videoData.id!,
      title: videoData.id!,
      width: resolution.width,
      height: resolution.height,
      type: 'video',
      posters,
      customPoster: '',
      isExternal: false,
      qualities,
      videoUrl: '',
    },
    width: resolution.width,
    height: resolution.height,
  };
};

const getHighestMp4Resolution = (qualities: VideoQuality[]) => {
  const mp4s = qualities.filter((video) => video.formats[0] === 'mp4');
  const { width, height } = mp4s.sort((a, b) => b.width - a.width)[0];
  return { width, height };
};

export const wixImageToGallery = ({
  imageData,
  container,
  mockImages,
  getAltText,
}: {
  imageData: PlatformImage;
  container: { width: number; height: number };
  mockImages: boolean;
  getAltText: () => string;
}): GalleryImageItem => ({
  itemId: imageData.id!,
  mediaUrl: getImageUrl(imageData, {
    maxWidth: container.width,
    maxHeight: container.height,
    type: 'fit',
    mockImages,
  }),
  metaData: {
    type: 'image',
    height: imageData.height!,
    width: imageData.width!,
    title: imageData.altText ?? getAltText(),
    focalPoint: [0, 0],
  },
});
