import { round, reduce, range } from 'lodash';
import { ReviewsStateReady } from './reviews-types';
import { ReviewsSummary } from '../../common-types';
import { MAX_RATING } from '../../constants/ratings';
import { RatingsBreakdown } from '../../../controller/lib/reviews-api-types';

export const getReviewsSummary = (resourceState: ReviewsStateReady): ReviewsSummary => {
  const ratingsBreakdown = resourceState.ratingsBreakdown;
  const totalReviews = reduce(ratingsBreakdown, (sum, c) => sum + c, 0);
  const overall = round(
    reduce(ratingsBreakdown, (acc, count, rating) => acc + count * Number(rating), 0) /
      totalReviews,
    1,
  );
  const normalizedBreakdown = range(MAX_RATING, 0).reduce<RatingsBreakdown>((acc, val) => {
    acc[val] = ratingsBreakdown[val] ?? 0;
    return acc;
  }, {});
  return {
    ratingsBreakdown: normalizedBreakdown,
    overall,
    totalReviews,
  };
};
