import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { classes, st } from './verified-badge.st.css';
import { BadgePriority, Popover } from 'wix-ui-tpa';
import { Badge } from 'wix-ui-tpa/cssVars';
import { Check } from '@wix/wix-ui-icons-common/on-stage';
import TextExtended from '../text-extended/text-extended';
import { useConfiguration } from '../configuration/use-configuration';
import { VerifiedStyleOptions } from '~commons/settings/constants/verified-style-options';

export type VerifiedBadge = {
  verified: boolean;
  isBW?: boolean;
};

const VerifiedBadge = ({ verified, isBW }: VerifiedBadge) => {
  const t = useTranslate();
  const { verifiedStyleType } = useConfiguration();

  return verified ? (
    <Popover fixed={true} flip={false} placement="top" minWidth={320}>
      <Popover.Element>
        <Badge
          className={st(classes.root, {
            isBW,
            isTextOnly: verifiedStyleType === VerifiedStyleOptions.Text,
          })}
          priority={BadgePriority.secondary}
          icon={<Check size={16} />}
        >
          <TextExtended
            size={16}
            colorMapping={isBW ? 'black' : 'verifiedBadge'}
            fontMapping="content"
          >
            {t('verified.badge-name')}
          </TextExtended>
        </Badge>
      </Popover.Element>
      <Popover.Content>
        <TextExtended size={16} fontMapping="siteContent">
          {t('verified.tooltip-explanation')}
        </TextExtended>
      </Popover.Content>
    </Popover>
  ) : null;
};

export default VerifiedBadge;
