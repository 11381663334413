import { CurrentOwner } from '../../common-types';
import { Author, RawPermission } from '../../../controller/lib/reviews-api-types';

export type AppPermissionState = {
  CREATE_REVIEW: boolean;
  EDIT_REVIEW: (author: Author) => boolean;
  DELETE_REVIEW: (author: Author) => boolean;
  SET_REPLY: boolean;
  REMOVE_REPLY: boolean;
  VOTE: boolean;
};

export const getAppPermissionsState = ({
  currentOwner,
  hasReview,
  rawPermissions,
  currentUserReviewAuthor,
}: {
  currentOwner: CurrentOwner;
  hasReview: boolean;
  rawPermissions: RawPermission[];
  currentUserReviewAuthor?: Author;
}): AppPermissionState => {
  return {
    CREATE_REVIEW: rawPermissions.includes('CREATE_REVIEW') && !hasReview,

    EDIT_REVIEW: (author) =>
      rawPermissions.includes('UPDATE_REVIEW') ||
      (author.type === 'MEMBER' &&
        currentOwner.type === 'MEMBER' &&
        author.id === currentOwner.member.contactId) ||
      (author.type === 'VISITOR' &&
        currentUserReviewAuthor?.type === 'VISITOR' &&
        currentUserReviewAuthor.id === author.id),

    DELETE_REVIEW: (author) =>
      rawPermissions.includes('DELETE_REVIEW') ||
      (author.type === 'MEMBER' &&
        currentOwner.type === 'MEMBER' &&
        author.id === currentOwner.member.contactId) ||
      (author.type === 'VISITOR' &&
        currentUserReviewAuthor?.type === 'VISITOR' &&
        currentUserReviewAuthor.id === author.id),

    SET_REPLY: rawPermissions.includes('SET_REPLY'),

    REMOVE_REPLY: rawPermissions.includes('REMOVE_REPLY'),

    VOTE: rawPermissions.includes('VOTE'),
  };
};
