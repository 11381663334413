import React, { useEffect } from 'react';
import TextExtended from '../components/text-extended/text-extended';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { ButtonExtended } from '~commons/components/button-extended/button-extended';
import { classes, st } from './list-fetch-error-state.st.css';
import { useApi } from '../components/api-provider/use-api';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const ListFetchErrorState: React.FC<{ onRetry?: () => void }> = ({ onRetry }) => {
  const t = useTranslate();
  const { isMobile } = useEnvironment();
  const { biShowFailedToLoadEmptyState } = useApi((state, actions) => ({
    biShowFailedToLoadEmptyState: actions.biShowFailedToLoadEmptyState,
  }));
  useEffect(() => {
    biShowFailedToLoadEmptyState();
  }, [biShowFailedToLoadEmptyState]);

  return (
    <div className={st(classes.container, { isMobile })}>
      <div className={classes.text}>
        <TextExtended size={isMobile ? 16 : 20}>{t('empty-state.list-fetch-error')}</TextExtended>
      </div>
      <div className={classes.text}>
        <TextExtended size={isMobile ? 14 : 16}>
          {t('empty-state.list-fetch-error-subtext')}
        </TextExtended>
      </div>
      <div className={classes.buttonContainer}>
        <ButtonExtended onClick={onRetry} mapping="callToAction">
          {t('empty-state.list-fetch-error-try-again')}
        </ButtonExtended>
      </div>
    </div>
  );
};

export default ListFetchErrorState;
