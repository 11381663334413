import React from 'react';
import TextExtended from '../text-extended/text-extended';
import TimeAgo from '../time-ago/time-ago';
import { REVIEW_AUTHOR } from '../../../common/constants/data-hooks';
import { Author } from '../../../controller/lib/reviews-api-types';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { classes, st } from './user-info.st.css';
import { useConfiguration } from '~reviews/Widget/components/configuration/use-configuration';
import { useVisibilityStyles } from '~reviews/Widget/components/visibility-styles/use-visibility-styles';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const UserInfo: React.FC<{
  className?: string;
  author?: Author;
  time?: string | Date;
}> = ({ author, time, className }) => {
  const { isReviewDateEnabled } = useConfiguration();
  const { isCssPerBreakpoint } = useEnvironment();
  const { isReviewDateEnabled: isReviewDateEnabledInAnyBp } = useVisibilityStyles();
  const shouldShowTime =
    !!time && (isCssPerBreakpoint ? isReviewDateEnabledInAnyBp : isReviewDateEnabled);
  const t = useTranslate();
  return (
    <div className={st(classes.root, className)}>
      <div className={classes.content}>
        {author ? (
          <TextExtended dataHook={REVIEW_AUTHOR} className={classes.username}>
            {author.type === 'UNKNOWN' || !author.name
              ? t('review.unknown-private-member-name')
              : author.name}
          </TextExtended>
        ) : null}
        {author && shouldShowTime ? (
          <TextExtended aria-hidden={true} className={st(classes.dot, classes.dateVisibility)}>
            •
          </TextExtended>
        ) : null}
        {shouldShowTime && (
          <TimeAgo
            className={st(classes.time, classes.dateVisibility)}
            time={time}
            key="time-ago"
          />
        )}
      </div>
    </div>
  );
};

export default UserInfo;
