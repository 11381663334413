import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';

const forDirection =
  <T extends {}>(LtrComponent: React.ComponentType<T>, RtlComponent: React.ComponentType<T>) =>
  ({ ...props }: T) => {
    const { isRTL } = useEnvironment();
    const Component = isRTL ? RtlComponent : LtrComponent;
    return <Component {...props} />;
  };

export default forDirection;
