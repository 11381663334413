import React from 'react';

export const ThreeDotSmallIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fillRule="evenodd">
      <path
        d="M11.963 13c-.547-.02-.976-.477-.963-1.025.014-.547.465-.982 1.012-.975.548.007.988.453.988 1-.01.563-.475 1.01-1.037 1zm0-4c-.547-.02-.976-.477-.963-1.025.014-.547.465-.982 1.012-.975.548.007.988.453.988 1-.01.563-.475 1.01-1.037 1zm.074 6c.547.02.976.477.963 1.025-.014.547-.465.982-1.012.975-.548-.007-.988-.453-.988-1 .012-.562.475-1.01 1.037-1z"
        transform="translate(-280 -194) translate(0 189) translate(20) translate(260 5)"
      />
    </g>
  </svg>
);
