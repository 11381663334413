import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import TextExtended from '../text-extended/text-extended';
import TimeAgo from '../time-ago/time-ago';
import { REPLY_AUTHOR, REPLY_DATE } from '../../../common/constants/data-hooks';
import { useApi } from '~reviews/Widget/components/api-provider/use-api';
import { classes } from './site-owner-info.st.css';
import { useConfiguration } from '../configuration/use-configuration';

export const SiteOwnerInfo: React.FC<{ time?: string }> = ({ time }) => {
  const { ownerDisplayName } = useApi((state) => state.configuration);
  const { isReviewDateEnabled } = useConfiguration();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <TextExtended data-hook={REPLY_AUTHOR} className={classes.ownerName}>
        {ownerDisplayName || t('site-owner-info.reply-as')}
      </TextExtended>
      {isReviewDateEnabled && (
        <>
          <TextExtended className={classes.dot}>•</TextExtended>
          <div className={classes.row} data-hook={REPLY_DATE}>
            {time && <TimeAgo className={classes.time} time={time} key="time-ago" />}
          </div>
        </>
      )}
    </div>
  );
};
