import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  makeListSelector,
  makeRootFormSelector,
  makeRootSelector,
} from '~reviews/common/services/id-utils';
import { ComponentStateReady } from '~reviews/common/store/reviews/reviews-types';
import ReviewListSection from '../review-list/review-list';
import TopSection from '../top-section/top-section';
import { useHighlight } from './use-highlight';

export const AppReady = ({ appState }: { appState: ComponentStateReady }) => {
  const { resourceId, namespace } = appState.config;
  useHighlight({
    highlight: appState.type === 'READY' ? appState.highlight : undefined,
    isMobile: useEnvironment().isMobile,
    listSelector: makeListSelector(namespace, resourceId),
    formSelector: makeRootFormSelector(namespace, resourceId),
    rootSelector: makeRootSelector(namespace, resourceId),
  });

  return (
    <>
      <TopSection sectionState={appState.topSection} />
      <ReviewListSection
        componentState={appState.listSection}
        rawPermissions={appState.rawPermissions}
      />
    </>
  );
};
