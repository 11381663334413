import React from 'react';
import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import { SectionNotification } from 'wix-ui-tpa/cssVars';
import { classes } from './info-alert.st.css';

export const InfoAlert = ({ alertText }: { alertText: string }) => {
  return (
    <SectionNotification type="wired" className={classes.root}>
      <SectionNotification.Icon icon={<InfoCircle />} />
      <SectionNotification.Text>{alertText}</SectionNotification.Text>
    </SectionNotification>
  );
};
