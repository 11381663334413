import { copyTextToClipboard } from '../../../common/services/copy-text-to-clipboard';

export function share({
  url,
  isMobile,
  afterCopy,
}: {
  url: string;
  isMobile: boolean;
  afterCopy: () => void;
}) {
  const clipboardFlow = () => {
    copyTextToClipboard(url);
    afterCopy();
  };
  if (isMobile) {
    openNativeShareIfSupported({ url }, clipboardFlow);
  } else {
    clipboardFlow();
  }
}

export function openNativeShareIfSupported({ url }: { url: string }, fallback: () => void) {
  if (typeof navigator !== 'undefined' && navigator.share) {
    navigator.share({ url });
  } else {
    fallback();
  }
}
