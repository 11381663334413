import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { HeaderRatingFull } from '../header-rating-full';
import { WidgetTitle } from '../widget-title';
import { CallToActionButton } from '../call-to-action-button';
import { classes } from './header-mobile.st.css';
import { RatingsBreakdown } from '../ratings-breakdown';
import { HeaderPropType } from './index';
import { useConfiguration } from '../../configuration/use-configuration';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const HeaderMobile: React.FC<HeaderPropType> = ({ reviewsSummary, callToAction }) => {
  const t = useTranslate();
  const settings = useConfiguration();
  const { isCssPerBreakpoint } = useEnvironment();
  return (
    <section aria-label={t('top-section.section-area-label')} className={classes.root}>
      {settings.isHeaderTitleEnabled || isCssPerBreakpoint ? (
        <WidgetTitle className={classes.title} />
      ) : null}
      <HeaderRatingFull
        className={classes.rating}
        value={reviewsSummary.overall}
        ratingDisplay={reviewsSummary.overall.toFixed(1)}
      />
      {settings.isReviewBarchartEnabled && (
        <div className={classes.breakdownContainer}>
          <RatingsBreakdown
            breakdown={reviewsSummary.ratingsBreakdown}
            totalReviews={reviewsSummary.totalReviews}
            clickable={settings.isFilterEnabled}
            withIcons
          />
        </div>
      )}
      {callToAction && (
        <CallToActionButton className={classes.callToActionButton} callToAction={callToAction} />
      )}
    </section>
  );
};
