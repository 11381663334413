import { useApi } from '~reviews/Widget/components/api-provider/use-api';
import { FormSettings } from '~commons/components/review-form/types';
import { useFormSettings as useCommonFormSettings } from '~commons/hooks/use-form-settings';

export const useFormSettings = (requireContact: boolean = false): FormSettings => {
  const { configuration } = useApi((state) => ({
    configuration: state.configuration,
    isCurrentMember: state.members.currentOwner.type === 'MEMBER',
  }));
  return useCommonFormSettings({
    configuration,
    contactSettings: {
      name: { enabled: requireContact },
      email: { enabled: requireContact },
    },
  });
};
