import React from 'react';
import { style, classes } from './header-rating-compact.st.css';
import { LargeStars } from '@wix/ftsystem';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';

export const HeaderRatingCompact: React.FC<{
  value: number;
  ratingDisplay: string;
  className?: string;
}> = ({ value, ratingDisplay, className }) => {
  const t = useTranslate();
  return (
    <div className={style(classes.root, className)}>
      <LargeStars
        a11yLabel={t('review.rating-aria-label', { rating: value })}
        className={classes.stars}
        rating={value}
      />
      <div className={classes.ratingDisplay}>{ratingDisplay}</div>
    </div>
  );
};
