import React from 'react';
import { useConfiguration } from '../../configuration/use-configuration';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { HeaderDesktopCompact } from './header-desktop-compact';
import { HeaderDesktopFull } from './header-desktop-full';
import { HeaderPropType } from './index';
import { HeaderRatingCompact } from '../header-rating-compact';
import { classes } from './header-desktop.st.css';

export const HeaderDesktop: React.FC<HeaderPropType> = ({ reviewsSummary, callToAction }) => {
  const settings = useConfiguration();
  const t = useTranslate();
  return settings.isReviewBarchartEnabled ? (
    <HeaderDesktopFull reviewsSummary={reviewsSummary} callToAction={callToAction} />
  ) : (
    <HeaderDesktopCompact callToAction={callToAction}>
      <HeaderRatingCompact
        className={classes.rating}
        value={reviewsSummary.overall}
        ratingDisplay={t('top-section.total-rating-compact', {
          rating: reviewsSummary.overall.toFixed(1),
          count: reviewsSummary.totalReviews,
        })}
      />
    </HeaderDesktopCompact>
  );
};
