import React from 'react';
import { classes, st } from './masonry.st.css';

// TODO: if only 1 colmn fits, then render only 1 so order is correct
// to achieve this we somehow need to know that this is not SSR/Hydration rendering
//
// other TODOS
// - BI
// - test
// reviews limit x2 kai small cards?

type MasonryProps = {
  columnCount: number;
  columnClass?: string;
};

export const Masonry: React.FC<React.PropsWithChildren<MasonryProps>> = ({
  children,
  columnCount,
  columnClass,
}) => {
  const getItemsInColumns = () => {
    const itemsInColumns = new Array(columnCount);
    const items = children ? React.Children.toArray(children) : [];

    for (let i = 0; i < items.length; i++) {
      const columnIndex = i % columnCount;

      if (!itemsInColumns[columnIndex]) {
        itemsInColumns[columnIndex] = [];
      }

      itemsInColumns[columnIndex].push(items[i]);
    }
    return itemsInColumns;
  };

  const renderColumns = () => {
    const childrenInColumns = getItemsInColumns();

    return childrenInColumns.map((items, i) => {
      return (
        <div key={i} className={st(classes.column, columnClass)}>
          {items}
        </div>
      );
    });
  };

  return <div className={st(classes.root)}>{renderColumns()}</div>;
};
