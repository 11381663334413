import { unreachable } from '~/ts-utils';
import { CurrentUserVoteState, VoteDisplayed, VotesState } from './review-vote-types';

export const getUpvotesDisplayed = ({
  currentUserVote,
  scoresWithoutUserVote,
}: VotesState): VoteDisplayed => {
  const optimisticUserVoteStateType = ((): CurrentUserVoteState['type'] => {
    const pendingAction = currentUserVote.pendingAction;
    if (!pendingAction) {
      return currentUserVote.type;
    }
    switch (currentUserVote.type) {
      case 'UPVOTED':
        return pendingAction === 'DOWNVOTE' ? 'DOWNVOTED' : 'NEUTRAL';
      case 'DOWNVOTED':
        return pendingAction === 'UPVOTE' ? 'UPVOTED' : 'NEUTRAL';
      case 'NEUTRAL':
        return pendingAction === 'UPVOTE' ? 'UPVOTED' : 'DOWNVOTED';
      default:
        throw unreachable(currentUserVote);
    }
  })();
  const pending = !!currentUserVote.pendingAction;
  switch (optimisticUserVoteStateType) {
    case 'UPVOTED': {
      return {
        pending,
        upvotes: { score: scoresWithoutUserVote.upvotes + 1, marked: true },
        downvotes: { score: scoresWithoutUserVote.downvotes, marked: false },
      };
    }
    case 'DOWNVOTED': {
      return {
        pending,
        upvotes: { score: scoresWithoutUserVote.upvotes, marked: false },
        downvotes: { score: scoresWithoutUserVote.downvotes + 1, marked: true },
      };
    }
    case 'NEUTRAL': {
      return {
        pending,
        upvotes: { score: scoresWithoutUserVote.upvotes, marked: false },
        downvotes: { score: scoresWithoutUserVote.downvotes, marked: false },
      };
    }
    default:
      throw unreachable(optimisticUserVoteStateType);
  }
};
