import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import TextExtended from '../text-extended/text-extended';
import { st, classes } from './header-rating-full.st.css';
import { LargeStars } from '@wix/ftsystem';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { AVERAGE_RATING } from '~reviews/common/constants/data-hooks';

export const HeaderRatingFull: React.FC<{
  value: number;
  countDisplay?: string;
  ratingDisplay: string;
  className?: string;
}> = ({ value, countDisplay, ratingDisplay, className }) => {
  const { isMobile } = useEnvironment();
  const t = useTranslate();
  return (
    <div className={st(classes.root, { mobile: isMobile }, className)}>
      <div className={classes.firstRow}>
        <LargeStars
          a11yLabel={t('review.rating-aria-label', { rating: value })}
          className={classes.stars}
          rating={value}
        />
        <span aria-hidden={true} className={classes.ratingText} data-hook={AVERAGE_RATING}>
          {ratingDisplay}
        </span>
      </div>
      {countDisplay && (
        <TextExtended tag="div" className={classes.countDisplay}>
          {countDisplay}
        </TextExtended>
      )}
    </div>
  );
};
