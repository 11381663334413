import { isOfType } from '~/ts-utils';
import { ReviewsListState } from '../reviews/review-state-types';

export const hasNextChunk = (listState: ReviewsListState): boolean => {
  if (isOfType(['ONLY_USER_REVIEW', 'DEEP_LINK'], listState)) {
    return false;
  }
  if (listState.type === 'READY') {
    return !!listState.chunk.nextCursor;
  }
  return hasNextChunk(listState.prevState);
};
export const hasPrevChunk = (listState: ReviewsListState): boolean => {
  if (isOfType(['ONLY_USER_REVIEW', 'DEEP_LINK'], listState)) {
    return false;
  }
  if (listState.type === 'READY') {
    return !!listState.chunk.previousCursor;
  }
  return hasPrevChunk(listState.prevState);
};
