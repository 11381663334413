import React from 'react';
import { getAppPermissionsState } from '../../common/store/reviews/reviews-permissions';
import { getCurrentUserReview } from '../../common/store/reviews/reviews-selectors';
import { RawPermission } from '../../controller/lib/reviews-api-types';
import { useApi } from '../components/api-provider/use-api';

export const useAppPermissionState = (rawPermissions: RawPermission[]) => {
  const { currentOwner, currentUserReview } = useApi((state) => ({
    currentOwner: state.members.currentOwner,
    currentUserReview: getCurrentUserReview(state.reviews),
  }));
  return React.useMemo(
    () =>
      getAppPermissionsState({
        currentOwner,
        hasReview: false,
        rawPermissions,
        currentUserReviewAuthor: currentUserReview?.author,
      }),
    [currentOwner, rawPermissions, currentUserReview?.author],
  );
};
