import React from 'react';
import { Divider } from 'wix-ui-tpa/cssVars';
import { classes, st } from './dividers.st.css';

type HorizontalDividerProps = {
  className?: string;
  isBlack?: boolean;
};

export const HorizontalDivider = ({ className, isBlack = false }: HorizontalDividerProps) => (
  <Divider className={st(classes.root, { isBlack, theme: !isBlack }, className)} />
);
