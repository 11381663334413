import { useEnvironment } from '@wix/yoshi-flow-editor';
import React from 'react';
import { useApi } from '../api-provider/use-api';

export const DevToolsPanel = React.lazy(
  () => import(/* webpackChunkName: "wr-dev-tools-panle" */ './dev-tools-panel'),
);

const DebugButton = () => {
  const { isSSR } = useEnvironment();
  const { toggle, isOpen } = useApi((state, _a, _h, ctx) => ({
    isOpen: ctx.devToolsState.panelIsOpen,
    toggle: () => ctx.devToolsApi.set('panelIsOpen', !ctx.devToolsState.panelIsOpen),
  }));
  return (
    <>
      <button
        style={{
          border: '1px solid #999',
          padding: '0px 10px 3px',
          opacity: '0.5',
          backgroundColor: '#ccc',
          borderRadius: '6px',
          textAlign: 'center',
          position: 'absolute',
          top: '-30px',
          left: '50%',
          marginLeft: '-50px',
          cursor: 'pointer',
          zIndex: 100,
        }}
        onClick={toggle}
      >
        reviews debug
      </button>
      {!isSSR && isOpen && (
        <React.Suspense fallback={null}>
          <DevToolsPanel toggle={toggle} />
        </React.Suspense>
      )}
    </>
  );
};

export default DebugButton;
