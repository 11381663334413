import React from 'react';
import { ThreeDotIcon } from '../icons/three-dot-icon';
import { ACTIONS_MENU, ACTIONS_MENU_BTN } from '../../../common/constants/data-hooks';
import { ActionMenuProps } from './actions-menu-types';
import ActionsMenuItemsDesktop from './actions-menu-items-desktop';
import { VerticallyPositionedContainer } from '../vertically-positioned-container/vertically-positioned-container';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import DropdownButton from '../dropdown-button/dropdown-button';
import { classes } from './actions-menu-desktop.st.css';

const ActionsMenuDesktop: React.FC<ActionMenuProps> = (props) => {
  const isEmpty = !props.share && !props.edit && !props.delete && !props.report;
  const t = useTranslate();

  if (isEmpty && !props.showDummy) {
    return null;
  }

  return (
    <DropdownButton
      className={classes.container}
      buttonProps={{
        'data-hook': ACTIONS_MENU_BTN,
        className: classes.moreButton,
        'aria-label': t('more-button.more-actions'),
        children: <ThreeDotIcon />,
      }}
      disabled={isEmpty}
      onShowCallback={() => props.onShowMenu?.()}
    >
      {(isKbTriggered) => (
        <VerticallyPositionedContainer
          dataHook={ACTIONS_MENU}
          className={classes.actionsContainer}
          offset={36}
        >
          <ActionsMenuItemsDesktop {...props} shouldAutofocus={isKbTriggered} />
        </VerticallyPositionedContainer>
      )}
    </DropdownButton>
  );
};

export default ActionsMenuDesktop;
