import { useStyles } from '@wix/tpa-settings/react';
import { visibilityStyleParams, VisibilityStylesParams } from '~reviews/stylesParams';

type Styles = { [K in keyof VisibilityStylesParams]: boolean };

export const useVisibilityStyles = (): Styles => {
  const { getStylesForAllBreakpoints } = useStyles();
  const allBreakpointsBooleanStyles = getStylesForAllBreakpoints().map((styles) => styles.booleans);
  return Object.keys(visibilityStyleParams).reduce(
    (acc, key) => ({
      ...acc,
      [key as keyof Styles]: Boolean(
        allBreakpointsBooleanStyles.map((styles) => styles[key]).find(Boolean),
      ),
    }),
    {} as Styles,
  );
};
