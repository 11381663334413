import React from 'react';
import { Button, ThumbnailImage } from 'wix-ui-tpa/cssVars';
import { Media } from '~commons/types';
import { MEDIA_SHOW_MORE, MEDIA_THUMBNAIL } from '../../../common/constants/data-hooks';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { getImageUrl, getVideoPosterUrl } from '~commons/components/review-form/get-media-url';
import { classes } from './thumbnails-list.st.css';
import { useConfiguration } from '../configuration/use-configuration';

const ThumbnailsList: React.FC<{
  media: Media[];
  onClick: (index: number) => void;
  width?: number;
  height?: number;
  /* max amount of items after which we display show more button  */
  maxItems?: number;
}> = ({ media, onClick, width = 100, height = 100, maxItems }) => {
  const t = useTranslate();
  const { mockImages } = useConfiguration();

  const trimState =
    maxItems !== undefined && media.length > maxItems
      ? {
          type: 'yes' as const,
          media: media.slice(0, maxItems - 1),
          itemCount: maxItems,
          showMoreCount: media.length - maxItems + 1,
        }
      : { type: 'no' as const, media, itemCount: media.length };

  const [focusedIndex, setFocusedIndex] = React.useState(0);
  const buttonRefs = React.useRef<HTMLButtonElement[]>([]);

  const setRef = (index: number) => (element: HTMLButtonElement) => {
    buttonRefs.current[index] = element;
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        e.preventDefault();
        const nextIndex = focusedIndex <= 0 ? trimState.itemCount - 1 : focusedIndex - 1;
        setFocusedIndex(nextIndex);
        buttonRefs.current[nextIndex]?.focus();
        break;
      }
      case 'ArrowRight':
      case 'ArrowDown': {
        e.preventDefault();
        const nextIndex = focusedIndex >= trimState.itemCount - 1 ? 0 : focusedIndex + 1;
        setFocusedIndex(nextIndex);
        buttonRefs.current[nextIndex]?.focus();
        break;
      }
    }
  };

  return (
    <ul className={classes.root} aria-label={t('review.media-aria-label')}>
      {trimState.media.map((m, index) => (
        <li
          key={'image' in m ? m.image.id : m.video.id}
          className={classes.listItem}
          style={{ height }}
        >
          <button
            tabIndex={index === focusedIndex ? 0 : -1}
            className={classes.imageButton}
            onKeyDown={onKeyDown}
            onClick={() => onClick(index)}
            ref={setRef(index)}
            aria-label={t('review.media-thumbnail-aria-label', {
              filename:
                ('image' in m ? m.image.filename : m.video.resolutions?.[0]?.poster?.filename) ??
                '',
            })}
            data-hook={MEDIA_THUMBNAIL}
          >
            {'image' in m ? (
              <ThumbnailImage
                key={m.image.id}
                width={width}
                height={height}
                src={getImageUrl(m.image, {
                  maxWidth: 200,
                  maxHeight: 200,
                  type: 'fill',
                  mockImages,
                })}
              />
            ) : (
              <ThumbnailImage
                key={m.video.id}
                width={width}
                height={height}
                src={getVideoPosterUrl(m.video.resolutions?.[0]?.poster!, {
                  maxWidth: 200,
                  maxHeight: 200,
                  type: 'fill',
                })}
              />
            )}
          </button>
        </li>
      ))}
      {trimState.type === 'yes' && (
        <li key="showMore">
          <Button
            tabIndex={trimState.itemCount === focusedIndex ? 0 : -1}
            upgrade
            ref={setRef(trimState.itemCount - 1)}
            onKeyDown={onKeyDown}
            className={classes.showMoreButton}
            onClick={() => onClick(trimState.itemCount - 1)}
            data-hook={MEDIA_SHOW_MORE}
          >
            +{trimState.showMoreCount}
          </Button>
        </li>
      )}
    </ul>
  );
};

export default ThumbnailsList;
