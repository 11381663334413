import React from 'react';
import AddItemIcon from 'wix-ui-icons-common/system/AddItemSmall';
import { st, classes } from './add-item.st.css';

export const AddItem: React.FC<{
  error: boolean;
  disabled?: boolean;
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  'aria-label': string;
}> = ({ 'aria-label': ariaLabel, error, className, onClick, disabled = false }) => {
  return (
    <button
      aria-label={ariaLabel}
      disabled={disabled}
      className={st(classes.root, { error, disabled }, className)}
      onClick={onClick}
      type="button"
    >
      <AddItemIcon />
    </button>
  );
};
