import { ApiContext } from './api-context';
import { ApiContextReady } from './api-context-types';
import { ReviewsControllerActions } from '../../../common/common-types';
import { State } from '../../../common/store/store-types';
import { useContextSelector } from '~commons/use-context-selector';
import { isShallowEqual } from '~commons/services/is-shallow-equal';

type RuntimeMapper<T> = (
  state: State,
  actions: ReviewsControllerActions,
  host: ApiContextReady['host'],
  ctx: ApiContextReady,
) => T;

/**
 * @internal
 * Optimized hook which will trigger rerender only if result is not shallowly equal.
 * Avoid creating new values in it. Don't:
 * const { myNevVal } = useApi(state => ( { nyNewVal: () => state.soemething }));
 */
export function useApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T {
  return useContextSelector(
    ApiContext,
    (ctx) => {
      if (ctx.type !== 'READY') {
        throw new Error('useApi used outside ApiContext or it was unitialized');
      }
      const { context } = ctx;
      return mapRuntimeToProps(context.state, context.actions, context.host, context);
    },
    isShallowEqual,
  );
}

// If used outside of ApiContextGuard
export function useUnsafeApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T | undefined {
  return useContextSelector(
    ApiContext,
    (ctx) => {
      if (ctx.type !== 'READY') {
        return undefined;
      }
      const { context } = ctx;
      return mapRuntimeToProps(context.state, context.actions, context.host, context);
    },
    isShallowEqual,
  );
}

// For parts outside of guard
export function useHostProps<T>(
  mapRuntimeToProps: (hostProps: Pick<ApiContextReady, 'host'>) => T,
): T | undefined {
  return useContextSelector(
    ApiContext,
    (ctx) => {
      if (ctx.type === 'NULL') {
        throw new Error('useApi used outside ApiContext or host props not passed');
      }
      const { context } = ctx;
      return mapRuntimeToProps(context);
    },
    isShallowEqual,
  );
}
