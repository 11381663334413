import React from 'react';
import { CallToActionState, TopSectionState } from '../../../common/store/reviews/reviews-types';
import RootReviewForm from '../review-form/root-review-form';
import { ReviewsListHeader } from '../reviews-list-header';
import { useApi } from '../api-provider/use-api';
import ZeroReviewsEmptyState from '../../empty-states/zero-reviews-empty-state';
import { Header } from './header';
import { classes, st } from './top-section.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { HeaderDesktopCompact } from './header/header-desktop-compact';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import TextExtended from '~reviews/Widget/components/text-extended/text-extended';
import { HEADER_SUBTITLE } from '~reviews/common/constants/data-hooks';

const TopSection: React.FC<{
  sectionState: TopSectionState;
}> = ({ sectionState }) => {
  const t = useTranslate();
  const { isMobile } = useEnvironment();
  const { callToActionClicked, biClickLeaveReviewBtn, biClickShowMyReview } = useApi(
    (state, actions) => ({
      callToActionClicked: actions.callToActionClicked,
      biClickLeaveReviewBtn: actions.biClickLeaveReviewBtn,
      biClickShowMyReview: actions.biClickShowMyReview,
    }),
  );

  const getCTAState = (callToAction: CallToActionState) => {
    return callToAction === 'NONE'
      ? undefined
      : {
          type: callToAction,
          onClick: () => {
            let button;
            if (callToAction === 'VIEW_MY_REVIEW') {
              biClickShowMyReview();
              button = 'view' as const;
            } else {
              biClickLeaveReviewBtn({ origin: 'product_page' });
              button = 'create' as const;
            }
            return callToActionClicked({
              button,
            });
          },
        };
  };

  const renderForm = () => (
    <RootReviewForm
      className={st(classes.form, { isMobile })}
      formState={sectionState.reviewForm}
    />
  );

  switch (sectionState.type) {
    case 'EMPTY': {
      return (
        <>
          {sectionState.reviewForm.type === 'HIDDEN' ? <ZeroReviewsEmptyState /> : null}
          {renderForm()}
        </>
      );
    }
    case 'UNPUBLISHED_REVIEW': {
      const callToActionButtonState = getCTAState(sectionState.callToAction);
      return (
        <>
          <HeaderDesktopCompact
            callToAction={callToActionButtonState}
            className={classes.unpublishedHeader}
          >
            <TextExtended dataHook={HEADER_SUBTITLE} size={20}>
              {t('top-section.no-reviews-subtitle')}
            </TextExtended>
          </HeaderDesktopCompact>
          {renderForm()}
        </>
      );
    }
    case 'FULL_LIST': {
      const { reviewsSummary } = sectionState;
      const callToActionButtonState = getCTAState(sectionState.callToAction);
      return (
        <>
          <Header reviewsSummary={reviewsSummary} callToAction={callToActionButtonState} />
          {renderForm()}
          <ReviewsListHeader
            className={classes.listHeader}
            selectedOrdering={sectionState.ordering}
            selectedFilter={sectionState.ratingFilter}
            reviewCount={
              sectionState.ratingFilter
                ? reviewsSummary.ratingsBreakdown[sectionState.ratingFilter]
                : reviewsSummary.totalReviews
            }
          />
        </>
      );
    }
  }
};

TopSection.displayName = 'TopSection';

export default TopSection;
