import React from 'react';
import { ThreeDotSmallIcon } from '../icons/three-dot-small-icon';
import { ACTIONS_MENU_BTN } from '../../../common/constants/data-hooks';
import { useModal, ReviewsActionSheetModal } from '../modals';
import { ActionMenuProps } from './actions-menu-types';
import { classes } from './actions-menu-mobile.st.css';

const ActionsMenuMobile: React.FC<ActionMenuProps> = (props) => {
  const showModal = useModal();
  const isEmpty = !props.share && !props.edit && !props.delete && !props.report;

  if (isEmpty && !props.showDummy) {
    return null;
  }

  return (
    <button
      className={classes.moreButton}
      data-hook={ACTIONS_MENU_BTN}
      onClick={
        isEmpty
          ? undefined
          : () => {
              props.onShowMenu?.();
              showModal((toggle) => <ReviewsActionSheetModal toggle={toggle} {...props} />);
            }
      }
    >
      <ThreeDotSmallIcon />
    </button>
  );
};

export default ActionsMenuMobile;
