import React from 'react';
import { DeleteIcon } from '../icons/delete-icon';
import { EditIcon } from '../icons/edit-icon';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { HorizontalDivider } from '../dividers';
import {
  ACTIONS_MENU_DELETE_BTN,
  ACTIONS_MENU_EDIT_BTN,
  ACTIONS_MENU_SHARE_BTN,
} from '../../../common/constants/data-hooks';
import { ActionMenuProps } from './actions-menu-types';
import { CopyLinkIcon } from '../icons/copy-link-icon';
import { classes } from './actions-menu-items-desktop.st.css';

const ActionsMenuItemsDesktop: React.FC<ActionMenuProps> = ({
  share,
  edit,
  delete: deleteAction,
  shouldAutofocus,
}) => {
  const beforeDivider = [
    share && (
      <ActionsMenuLayout.Item
        key="share"
        prefixIcon={<CopyLinkIcon data-hook={ACTIONS_MENU_SHARE_BTN} />}
        content={share.label}
        onClick={share.action}
      />
    ),
    edit && (
      <ActionsMenuLayout.Item
        key="edit"
        prefixIcon={<EditIcon data-hook={ACTIONS_MENU_EDIT_BTN} />}
        content={edit.label}
        onClick={edit.action}
      />
    ),
  ].filter(Boolean);

  const afterDivider = [
    deleteAction && (
      <ActionsMenuLayout.Item
        key="delete"
        prefixIcon={<DeleteIcon data-hook={ACTIONS_MENU_DELETE_BTN} />}
        content={deleteAction.label}
        onClick={deleteAction.action}
      />
    ),
  ].filter(Boolean);

  const items = [
    ...beforeDivider,
    Boolean(beforeDivider.length && afterDivider.length) && (
      <HorizontalDivider className={classes.divider} isBlack key="hr" />
    ),
    ...afterDivider,
  ].filter(Boolean);

  if (items.length === 0) {
    return null;
  }
  // The ActionsMenuLayout doesn't tolerate non react elements, so we do filtering before
  return (
    <div className={classes.container}>
      <ActionsMenuLayout
        className={classes.actionsMenu}
        focusedIndex={shouldAutofocus ? 0 : undefined}
      >
        {items}
      </ActionsMenuLayout>
    </div>
  );
};

export default ActionsMenuItemsDesktop;
