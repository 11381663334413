import React from 'react';

export const ArrowDownIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M11.999 15L6 9.759 6.657 9l5.342 4.666L17.343 9l.657.759z" />
  </svg>
);

export const ArrowUpIcon = (props: any) => (
  <ArrowDownIcon {...props} style={{ transform: 'scaleY(-1' }} />
);
