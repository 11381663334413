import React, { useRef, useState } from 'react';
import { classes } from './gallery-modal/gallery-modal-desktop.st.css';
import { Media } from '~commons/types';
import { Image } from 'wix-ui-tpa';
import { getImageUrl, getVideoUrl } from '~commons/components/review-form/get-media-url';
import { VideoPlayIcon } from '../icons/video-play-icon';
import { VIDEO_PLAYER_ELEMENT } from '~reviews/common/constants/data-hooks';

export const MediaItem: React.FC<{
  media: Media[];
}> = ({ media }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const videoResolutions = 'video' in media[0] ? media[0].video.resolutions?.[0] : undefined;

  const handleVideoPlay = () => {
    setIsPlaying(!isPlaying);
    if (!videoRef.current) {
      return;
    }

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  return 'image' in media[0] ? (
    <Image
      className={classes.imageContainer}
      src={getImageUrl(media[0].image, {
        maxWidth: media[0].image.width,
        maxHeight: media[0].image.height,
      })}
      width={media[0].image.width}
      height={media[0].image.height}
      fluid
      forceImageContain
    />
  ) : (
    <div
      className={classes.videoContainer}
      style={{
        aspectRatio: `${videoResolutions?.width}/${videoResolutions?.height}`,
      }}
      data-hook={VIDEO_PLAYER_ELEMENT}
    >
      <video
        ref={videoRef}
        className={classes.video}
        playsInline
        webkit-playsinline
        x5-playsinline
        controls={isPlaying}
        src={getVideoUrl(videoResolutions?.url!)}
        onClick={handleVideoPlay}
      />
      {!isPlaying && <VideoPlayIcon onVideoPlay={handleVideoPlay} />}
    </div>
  );
};
