export type QueryCommand =
  | {
      command: 'none';
    }
  | {
      command: 'openForm';
    }
  | {
      command: 'focusRoot';
    }
  | {
      command: 'openDeepLink';
      reviewId: string;
      resourceId?: string;
    };

/**
 * Open form: ?wrOpenNs=stores&wrOpenResId=abc
 * Focus component: ?wrFocusNs=stores&wrFocusResId=abc
 * Deep link: ?wrDeepNs=stores&wrDeepResId=abc&wrDeepRevId=123
 *
 * For slots it might be shorter, as we presume, that only one wixReviews component is on the page
 * Open form: ?wrOpenNs=stores
 * Deep link: ?wrDeepNs=stores&wrDeepRevId=123
 */
export const resolveQueryParams = ({
  query,
  namespace,
  resourceId,
  inSlot,
}: {
  query: {
    wrOpenNs?: string;
    wrOpenResId?: string;
    wrFocusNs?: string;
    wrFocusResId?: string;
    wrDeepNs?: string;
    wrDeepResId?: string;
    wrDeepRevId?: string;
  };
  namespace: string;
  resourceId: string;
  inSlot: boolean;
}): QueryCommand => {
  if (query.wrOpenNs === namespace) {
    if (inSlot && !query.wrOpenResId) {
      // For slot we presume that there will be only one review app per page
      // (this form should not be used if its not true)
      return { command: 'openForm' };
    }
    if (query.wrOpenResId === resourceId) {
      // For all other cases we require resourceId to be the same
      return { command: 'openForm' };
    }
  }
  if (query.wrFocusNs === namespace) {
    if (inSlot && !query.wrOpenResId) {
      // For slot we presume that there will be only one review app per page
      // (this form should not be used if its not true)
      return { command: 'focusRoot' };
    }
    if (query.wrFocusResId === resourceId) {
      // For all other cases we require resourceId to be the same
      return { command: 'focusRoot' };
    }
  }
  if (query.wrDeepRevId && query.wrDeepNs === namespace) {
    if (inSlot && !query.wrOpenResId) {
      return {
        command: 'openDeepLink',
        reviewId: query.wrDeepRevId,
        resourceId: query.wrDeepResId,
      };
    }
    if (query.wrDeepResId === resourceId) {
      return { command: 'openDeepLink', reviewId: query.wrDeepRevId };
    }
  }
  return { command: 'none' };
};

export const generateDeepLink = ({
  namespace,
  resourceId,
  reviewId,
}: {
  namespace: string;
  resourceId: string;
  reviewId: string;
}) => {
  const url = new URL(window.location.href);
  url.searchParams.set('wrDeepNs', namespace);
  url.searchParams.set('wrDeepResId', resourceId);
  url.searchParams.set('wrDeepRevId', reviewId);
  return url.href;
};
