import { highlightElement } from './highlight-element';

export const DEFAULT_CONFIG = {
  timeoutBeforeHighlight: 0,
  shouldScroll: true,
  shouldHighlightBackground: true,
  highlightDuration: 1500,
  smoothScroll: true,
  shouldFocus: true,
};

export type HighlightConfig = typeof DEFAULT_CONFIG;

export const HIGHLIGHT_CLASS = 'wr-highlight-bg-color';
export const BG_TRANSITION_CLASS = 'wr-bg-transition';

export const highlightSelector = (selector: string, highlightConfig: HighlightConfig) => {
  const element = document.querySelector(selector);
  element &&
    element instanceof HTMLElement &&
    setTimeout(
      () => highlightElement(element, highlightConfig),
      highlightConfig.timeoutBeforeHighlight,
    );
};

export const isOffscreen = (elementsRect: DOMRect): boolean => {
  // Account for the permium banner
  const offset = document.documentElement.clientHeight / 12;

  const tooHigh = elementsRect.top - offset < 0;
  const tooLow = elementsRect.top + elementsRect.height > document.documentElement.clientHeight;
  return tooHigh || tooLow;
};
