import React from 'react';
import { LinkButton } from './link-button';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import { style, classes } from './pager.st.css';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { NEXT_PAGE_BTN, PREV_PAGE_BTN } from '../../../common/constants/data-hooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const Pager: React.FC<{
  className?: string;
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onPrev: () => void;
  onNext: () => void;
  dataHook?: string;
}> = ({ isPrevDisabled, isNextDisabled, onPrev, onNext, dataHook, className }) => {
  const t = useTranslate();
  const { isRTL } = useEnvironment();
  return (
    <nav
      aria-label={t('top-section.title')}
      data-hook={dataHook}
      className={style(classes.root, className)}
    >
      <LinkButton
        className={classes.prevButton}
        disabled={isPrevDisabled}
        onClick={onPrev}
        dataHook={PREV_PAGE_BTN}
        text={t('pager.previous')}
        PrefixIcon={isRTL ? ChevronRight : ChevronLeft}
      />
      <LinkButton
        className={classes.nextButton}
        disabled={isNextDisabled}
        onClick={onNext}
        dataHook={NEXT_PAGE_BTN}
        text={t('pager.next')}
        SuffixIcon={isRTL ? ChevronLeft : ChevronRight}
      />
    </nav>
  );
};
