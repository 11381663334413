import React from 'react';

type DropdownButtonProps = {
  className?: string;
  disabled: boolean;
  buttonProps: any;
  initialState?: boolean;
  onShowCallback?: () => void;
  buttonComponent?: React.ComponentType<any>;
  children: React.ReactNode | ((isKbTriggered: boolean) => React.ReactNode);
};

const DropdownButton = ({
  className,
  disabled,
  buttonProps: { children: buttonChildren, ...buttonProps },
  initialState = false,
  onShowCallback,
  buttonComponent,
  ...props
}: DropdownButtonProps) => {
  const [isVisible, setIsVisible] = React.useState(initialState);
  const [isKbTriggered, setIsKbTriggered] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (isVisible) {
      document.addEventListener('click', hideDropdown);
      document.addEventListener('keyup', handleCloseDropdown);
    }
    return () => {
      document.removeEventListener('click', hideDropdown);
      document.removeEventListener('keyup', handleCloseDropdown);
    };
  }, [isVisible]);

  const hideDropdown = () => {
    setIsVisible(false);
  };

  const handleCloseDropdown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Tab') {
      e.key === 'Escape' && buttonRef.current?.focus();
      setIsVisible(false);
    }
  };

  const Component = buttonComponent ? buttonComponent : 'button';
  const children =
    typeof props.children === 'function' ? props.children(isKbTriggered) : props.children;
  return (
    <div className={className}>
      <Component
        {...buttonProps}
        onClick={(e) => {
          if (disabled || isVisible) {
            return;
          }

          e.stopPropagation();
          setIsKbTriggered(false);
          setIsVisible(true);
          onShowCallback?.();
        }}
        onKeyDown={(e) => {
          if (disabled || isVisible) {
            return;
          }
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            e.stopPropagation();
            setIsKbTriggered(true);
            setIsVisible(true);
            onShowCallback?.();
          }
        }}
        aria-haspopup="true"
        aria-expanded={isVisible}
        ref={buttonRef}
      >
        {buttonChildren ? buttonChildren : null}
      </Component>
      {isVisible ? children : null}
    </div>
  );
};

export default DropdownButton;
