import { Ordering } from '~reviews/controller/lib/reviews-api-types';
import { TranslationsFn } from '~reviews/Widget/hooks/use-translate';
import { range } from 'lodash';
import type { DropdownOptionTextOverflow } from 'wix-ui-tpa/cssVars';

const DISABLED_ORDERING_OPTIONS: Ordering[] = ['HIGHEST_RATED', 'LOWEST_RATED'];

type State = { ratingFilter: number | undefined; ordering: Ordering };

type OrderingAndFilterMachine = {
  getState: () => State;
  setFilter: (ratingFilter: number | undefined) => OrderingAndFilterMachine;
  setOrdering: (ordering: Ordering) => OrderingAndFilterMachine;
  setFilterAndOrdering: (
    ratingFilter: undefined | number,
    ordering: Ordering,
  ) => OrderingAndFilterMachine;
};

export const orderingAndFilterMachine = (state: State): OrderingAndFilterMachine => {
  const thisMachine: OrderingAndFilterMachine = {
    setFilter: (ratingFilter) => {
      if (ratingFilter === undefined) {
        return orderingAndFilterMachine({ ratingFilter: undefined, ordering: state.ordering });
      }

      const ordering = DISABLED_ORDERING_OPTIONS.includes(state.ordering)
        ? 'NEWEST'
        : state.ordering;
      return orderingAndFilterMachine({ ratingFilter, ordering });
    },
    setOrdering: (ordering) => {
      if (state.ratingFilter !== undefined && DISABLED_ORDERING_OPTIONS.includes(ordering)) {
        // Prevent incorrect ordering
        return thisMachine;
      }

      return orderingAndFilterMachine({ ratingFilter: state.ratingFilter, ordering });
    },
    setFilterAndOrdering: (ratingFilter, ordering) => {
      return thisMachine.setFilter(ratingFilter).setOrdering(ordering);
    },
    getState: () => state,
  };
  return thisMachine;
};

export const getOrderingOptions = (t: TranslationsFn, ratingFilter: number | undefined) => {
  return (
    [
      { id: 'MOST_RELEVANT', value: t('reviews-ordering.most-relevant') },
      { id: 'NEWEST', value: t('reviews-ordering.latest-first') },
      { id: 'OLDEST', value: t('reviews-ordering.oldest-first') },
      { id: 'HELPFUL', value: t('reviews-ordering.most-helpful') },
      { id: 'HIGHEST_RATED', value: t('reviews-ordering.highest-rated') },
      { id: 'LOWEST_RATED', value: t('reviews-ordering.lowest-rated') },
    ] as { id: Ordering; value: string }[]
  ).map((option) => ({
    ...option,
    isSelectable: ratingFilter === undefined || !DISABLED_ORDERING_OPTIONS.includes(option.id),
    textOverflow: 'wrap' as DropdownOptionTextOverflow,
  }));
};

export const getFilterOptions = (
  t: TranslationsFn,
): { id: string; value: string; isSelectable: boolean }[] => {
  return [
    {
      id: '',
      value: t('reviews-filter.all-stars'),
      isSelectable: true,
      textOverflow: 'wrap' as DropdownOptionTextOverflow,
    },
    ...range(5, 0, -1).map((count) => ({
      id: count.toString(),
      value: t('reviews-filter.n-stars', { count }),
      isSelectable: true,
      textOverflow: 'wrap' as DropdownOptionTextOverflow,
    })),
  ];
};

export function stringifyRatingFilterValue(value: number | undefined): string {
  return value?.toString() ?? '';
}

export function parseRatingFilterValue(value: string): number | undefined {
  const parsed = Number(value);
  return [1, 2, 3, 4, 5].includes(parsed) ? parsed : undefined;
}
