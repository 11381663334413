import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../../configuration/use-configuration';
import { CallToActionButton, CallToActionButtonState } from '../call-to-action-button';
import { WidgetTitle } from '../widget-title';
import { classes } from './header-desktop-compact.st.css';
import { useVisibilityStyles } from '~reviews/Widget/components/visibility-styles/use-visibility-styles';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const HeaderDesktopCompact: React.FC<
  React.PropsWithChildren<{
    callToAction?: CallToActionButtonState;
    className?: string;
  }>
> = ({ className, callToAction, children }) => {
  const t = useTranslate();
  const settings = useConfiguration();
  const { isCssPerBreakpoint } = useEnvironment();
  const { isHeaderTitleEnabled } = useVisibilityStyles();
  return (
    <section className={className} aria-label={t('top-section.section-area-label')}>
      {(isCssPerBreakpoint ? isHeaderTitleEnabled : settings.isHeaderTitleEnabled) ? (
        <WidgetTitle className={classes.title} />
      ) : null}
      <div className={classes.bottomBlock}>
        {children}
        {callToAction && (
          <CallToActionButton className={classes.callToActionButton} callToAction={callToAction} />
        )}
      </div>
    </section>
  );
};
