import React from 'react';
import TextExtended from '../components/text-extended/text-extended';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { LIST_LOADING } from '../../common/constants/data-hooks';
import { useConfiguration } from '../components/configuration/use-configuration';
import { classes, st } from './app-loading-state.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const AppLoadingState: React.FC<{}> = () => {
  const t = useTranslate();
  const { screenshotMode } = useConfiguration();
  const { isMobile } = useEnvironment();

  return (
    <div data-hook={LIST_LOADING} className={st(classes.container, { isMobile })}>
      <div className={classes.spinner}>
        <Spinner diameter={17} isStatic={screenshotMode} />
      </div>
      <div className={classes.text}>
        <TextExtended>{t('empty-state.list-loading-text')}</TextExtended>
      </div>
    </div>
  );
};

export default AppLoadingState;
