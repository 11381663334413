import { useEffect } from 'react';
import {
  DEFAULT_CONFIG,
  HighlightConfig,
  highlightSelector,
} from '~commons/services/highlight-utils';
import { highlightElement } from '~commons/services/highlight-element';
import { HighlightEffect } from '../../../common/store/reviews/reviews-types';
import { makeReviewSelector } from '../../../common/services/id-utils';

const getConfig = ({
  isMobile,
  type,
}: {
  isMobile: boolean;
  type: HighlightEffect['type'];
}): HighlightConfig =>
  ({
    CRUD_SUCCESS: { ...DEFAULT_CONFIG, shouldScroll: isMobile },
    DEEP_LINK: {
      ...DEFAULT_CONFIG,
      shouldHighlightBackground: false,
      highlightDuration: 5000,
      smoothScroll: false,
    },
    CRUD_ERROR: {
      ...DEFAULT_CONFIG,
      // TODO: It's nice when error toast show first and only then we scrool to the element, but to
      // be fair there is some kind of unresolved bug when timeout is 0. Strange that it appears
      // only with 'error' highlights, and not 'success' ones - I do suspect Ricos editor, because it
      // breaks when the editor in highlighted element is just rendered.
      timeoutBeforeHighlight: 500,
      shouldHighlightBackground: false,
    },
    NAVIGATION_PROMPT: {
      ...DEFAULT_CONFIG,
      shouldHighlightBackground: false,
    },
    SCROLL_TO_REVIEW: DEFAULT_CONFIG,
    FOCUS_REVIEW: { ...DEFAULT_CONFIG, shouldHighlightBackground: false },
    SCROLL_TO_TOP: { ...DEFAULT_CONFIG, shouldHighlightBackground: false, shouldFocus: false },
    FOCUS_ROOT: { ...DEFAULT_CONFIG, shouldHighlightBackground: false },
    FOCUS_FORM: { ...DEFAULT_CONFIG, shouldHighlightBackground: false },
    MODERATION_PROMPT: { ...DEFAULT_CONFIG, shouldScroll: isMobile },
  }[type]);

export const useHighlight = ({
  highlight,
  isMobile,
  listSelector,
  formSelector,
  rootSelector,
}: {
  highlight: HighlightEffect | undefined;
  isMobile: boolean;
  listSelector: string;
  formSelector: string;
  rootSelector: string;
}) => {
  const selector = (() => {
    if (!highlight) {
      return undefined;
    }
    if (highlight.type === 'SCROLL_TO_TOP') {
      return listSelector;
    }
    if (highlight.type === 'FOCUS_FORM') {
      return formSelector;
    }
    if (highlight.type === 'FOCUS_ROOT') {
      return rootSelector;
    }
    return makeReviewSelector(highlight.id);
  })();

  useEffect(() => {
    if (!highlight || !selector) {
      return;
    }
    const config = getConfig({ isMobile, type: highlight.type });
    highlightSelector(selector, config);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlight, selector, highlight?.timestamp, highlight?.type, isMobile]);
};

const highlightTarget = (selector: string, isMobile: boolean, type: HighlightEffect['type']) => {
  const element = document.querySelector(selector);
  if (element && element instanceof HTMLElement) {
    highlightElement(element, getConfig({ isMobile, type }));
  }
};

export const highlightNavigationPromptTarget = (selector: string, isMobile: boolean) =>
  highlightTarget(selector, isMobile, 'NAVIGATION_PROMPT');

export const highlightModerationPromptTarget = (selector: string, isMobile: boolean) =>
  highlightTarget(selector, isMobile, 'MODERATION_PROMPT');
