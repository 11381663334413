import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useConfiguration } from '../configuration/use-configuration';
import { style, classes } from './widget-title.st.css';
import { APP_TITLE } from '~reviews/common/constants/data-hooks';

export const WidgetTitle: React.FC<{ className?: string }> = ({ className }) => {
  const t = useTranslate();
  const Tag = useConfiguration().headerTitleHtmlTag as keyof JSX.IntrinsicElements;
  return (
    <Tag className={style(classes.title, classes.textSpan, className)} data-hook={APP_TITLE}>
      {t('top-section.title')}
    </Tag>
  );
};
