import React from 'react';
import UserInfo from '../user-info/user-info';
import classNames from 'classnames';
import { ReviewProps } from './review-props';
import { classes } from './review-mobile.st.css';
import {
  LIST_ITEM,
  REVIEW_CONTENT,
  REVIEW_RATING,
  REVIEW_TITLE,
  REVIEW_BODY,
  REVIEW_MEDIA,
  REVIEW_STATUS_BADGE,
} from '../../../common/constants/data-hooks';
import ListItemContainer from '../list-item-container/list-item-container';
import TextExtended from '../text-extended/text-extended';
import { RatingsInReview } from '../ratings-in-reviews/rating-in-reviews';
import ThumbnailsList from './thumbnails-list';
import CollapsibleText from '../collapsible-text/collapsible-text';
import { useFormSettings } from '~reviews/common/store/configuration/use-form-settings';
import VerifiedBadge from './verified-badge';

const ReviewMobile: React.FC<ReviewProps> = ({
  author,
  createdAt,
  content,
  verified,
  statusBadge,
  actionsMenu,
  id,
  footer,
  openMediaModal,
  onShowMoreClick,
}) => {
  const { title, body, media } = useFormSettings();

  return (
    <ListItemContainer id={id} dataHook={classNames(LIST_ITEM, REVIEW_CONTENT)} isReply={false}>
      <div className={classes.header}>
        <div className={classes.leftSide}>
          <UserInfo author={author} time={createdAt} />
        </div>
        <div className={classes.rightSide}>{actionsMenu}</div>
      </div>
      {statusBadge ? (
        <div className={classes.statusBadge} data-hook={REVIEW_STATUS_BADGE}>
          {statusBadge}
        </div>
      ) : null}
      <RatingsInReview value={content.rating} verified={verified} dataHook={REVIEW_RATING} />
      <div className={classes.main}>
        {title.enabled ? (
          <div className={classes.title}>
            <TextExtended
              fontMapping="reviewTitle"
              colorMapping="reviewTitle"
              data-hook={REVIEW_TITLE}
            >
              {content.title}
            </TextExtended>
          </div>
        ) : null}
        {body.enabled && content.body ? (
          <CollapsibleText
            clamp={5}
            onClick={onShowMoreClick}
            text={content.body}
            dataHook={REVIEW_BODY}
          />
        ) : null}
        {content.media.length > 0 && media.enabled && openMediaModal && (
          <div className={classes.media} data-hook={REVIEW_MEDIA}>
            <ThumbnailsList
              media={content.media}
              onClick={openMediaModal}
              width={80}
              height={80}
              maxItems={3}
            />
          </div>
        )}
      </div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </ListItemContainer>
  );
};
export default ReviewMobile;
