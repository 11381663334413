import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { triggerIosKeyboard } from '../../../common/services/ios-utils';
import { ReplyState } from '../../../common/store/reviews/replies/reply-types';
import { AppPermissionState } from '../../../common/store/reviews/reviews-permissions';
import { unreachable } from '~/ts-utils';
import { ActionsMenu } from '../actions-menu';
import { useApi } from '../api-provider/use-api';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { DeleteCommentConfirmationModal, useModal } from '../modals';
import { Reply } from '../reply';
import ReplyBox from '../reply-box';
import { getReplyHtmlId } from '../../../common/services/id-utils';

const ReplyStateSwitchNoMemo: React.FC<{
  replyState: ReplyState;
  appPermissionState: AppPermissionState;
}> = ({ replyState, appPermissionState }) => {
  const {
    createReply,
    updateReply,
    cancelEditingReply,
    cancelCreatingReply,
    biClickPublishReply,
    biCancelCreatingReply,
  } = useApi((state, actions) => ({
    createReply: actions.createReply,
    updateReply: actions.updateReply,
    cancelEditingReply: actions.cancelEditingReply,
    cancelCreatingReply: actions.cancelCreatingReply,
    biClickPublishReply: actions.biClickPublishReply,
    biCancelCreatingReply: actions.biCancelCreatingReply,
  }));
  const hasBeenUpdated = useRef(false);

  useEffect(() => {
    switch (replyState.type) {
      case 'REPLY_UPDATE_PENDING':
        hasBeenUpdated.current = true;
        return;
      case 'REPLY_EDITING':
      case 'REPLY_TYPING':
      case 'REPLY_READY':
      case 'REPLY_CREATE_PENDING':
      case 'REPLY_DELETE_PENDING':
        return;
      default: {
        throw unreachable(replyState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replyState.type]);

  switch (replyState.type) {
    case 'REPLY_TYPING':
    case 'REPLY_CREATE_PENDING': {
      const { parentId, content } = replyState;
      return (
        <ReplyBox
          id={getReplyHtmlId(parentId)}
          isEdit={false}
          isPending={replyState.type === 'REPLY_CREATE_PENDING'}
          initialContent={content}
          onSubmit={(_content, _reset) => {
            biClickPublishReply({ reviewId: parentId, isEdited: false });
            createReply({
              content: _content,
              parentId,
            });
          }}
          onCancel={(_content, _reset, isEmpty) =>
            isEmpty
              ? {
                  type: 'SILENT_CANCEL',
                  cleanup: () => {
                    biCancelCreatingReply({ reviewId: parentId, isEdited: false });
                    cancelCreatingReply({ parentId });
                  },
                }
              : {
                  type: 'PROMPT',
                  discard: 'COMMENT',
                  onConfirm: () => {
                    biCancelCreatingReply({ reviewId: parentId, isEdited: false });
                    cancelCreatingReply({ parentId });
                  },
                }
          }
        />
      );
    }
    case 'REPLY_READY': {
      const { reply, parentId } = replyState;
      return (
        <Reply
          id={getReplyHtmlId(parentId)}
          content={reply.content}
          createdAt={reply.createdDate as any}
          isPending={false}
          actionsMenu={
            <ActionsMenuReplyReady appPermissionState={appPermissionState} reviewId={parentId} />
          }
          disableShowMore={hasBeenUpdated.current}
        />
      );
    }
    case 'REPLY_UPDATE_PENDING':
    case 'REPLY_EDITING': {
      const { reply, content, parentId } = replyState;
      const initialContent = content || reply.content;
      return (
        <ReplyBox
          id={getReplyHtmlId(parentId)}
          isEdit={true}
          isPending={replyState.type === 'REPLY_UPDATE_PENDING'}
          initialContent={initialContent}
          onSubmit={(_content) => {
            biClickPublishReply({ reviewId: parentId, isEdited: true });
            updateReply({ content: _content, parentId });
          }}
          onCancel={(newContent) =>
            isEqual(newContent, replyState.reply.content)
              ? {
                  type: 'SILENT_CANCEL',
                  cleanup: () => {
                    biCancelCreatingReply({ reviewId: parentId, isEdited: true });
                    cancelEditingReply({ parentId });
                  },
                }
              : {
                  type: 'PROMPT',
                  discard: 'CHANGES',
                  onConfirm: () => {
                    biCancelCreatingReply({ reviewId: parentId, isEdited: true });
                    cancelEditingReply({ parentId });
                  },
                }
          }
        />
      );
    }
    case 'REPLY_DELETE_PENDING': {
      const { parentId, reply } = replyState;
      return (
        <Reply
          id={getReplyHtmlId(parentId)}
          content={reply.content}
          isPending={true}
          createdAt={reply.createdDate as any}
          actionsMenu={<ActionsMenu showDummy />}
          disableShowMore
        />
      );
    }
    default: {
      throw unreachable(replyState);
    }
  }
};

export const ReplyStateSwitch = React.memo(ReplyStateSwitchNoMemo);

const ActionsMenuReplyReady: React.FC<{
  appPermissionState: AppPermissionState;
  reviewId: string;
}> = ({ appPermissionState, reviewId }) => {
  const t = useTranslate();
  const showModal = useModal();
  const { editReply, deleteReply, biSelectReplyAction, biOpenReplyActions } = useApi(
    (_state, actions) => ({
      editReply: actions.editReply,
      deleteReply: actions.deleteReply,
      biSelectReplyAction: actions.biSelectReplyAction,
      biOpenReplyActions: actions.biOpenReplyActions,
    }),
  );
  return (
    <ActionsMenu
      onShowMenu={() =>
        biOpenReplyActions({
          reviewId,
        })
      }
      edit={
        appPermissionState.SET_REPLY
          ? {
              label: t('reply-actions.edit'),
              action: () => {
                biSelectReplyAction({
                  action: 'edit',
                  reviewId,
                });
                editReply({
                  parentId: reviewId,
                });
                triggerIosKeyboard();
              },
            }
          : undefined
      }
      delete={
        appPermissionState.REMOVE_REPLY
          ? {
              label: t('reply-actions.delete'),
              action: () => {
                biSelectReplyAction({
                  action: 'delete',
                  reviewId,
                });
                showModal((toggle) => (
                  <DeleteCommentConfirmationModal
                    isReply={true}
                    toggle={toggle}
                    onClose={(confirmed) => {
                      confirmed &&
                        deleteReply({
                          parentId: reviewId,
                        });
                    }}
                  />
                ));
              },
            }
          : undefined
      }
    />
  );
};
