import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams, { SettingsParamsTypes } from '~reviews/settingsParams';
import { useApi } from '../api-provider/use-api';
import { Configuration } from './configuration-types';
import { HelpfulVotes } from '@wix/ambassador-reviews-v1-enriched-live-site-review/types';

export function useConfiguration(): Configuration {
  const settings = useSettings();
  const { config, ...devParams } = useApi((s) => ({
    screenshotMode: s.baseParams.screenshotMode,
    mockImages: s.baseParams.mockImages,
    config: s.configuration,
  }));
  return React.useMemo(() => {
    const r = { ...devParams };

    Object.keys(settingsParams)
      .filter((key) => key !== 'isUpvotesEnabled' && key !== 'isDownvotesEnabled')
      .forEach((key) => {
        Object.defineProperty(r, key, {
          get: () => settings.get(settingsParams[key as keyof SettingsParamsTypes]),
        });
      });

    Object.defineProperty(r, 'votes', {
      get: () => resolveReviewsVoting(config.helpfulVotes),
    });

    return r as Configuration;
  }, [settings, devParams]);
}

const resolveReviewsVoting = ({ active = false, helpfulVotesOnly = false }: HelpfulVotes = {}) => {
  if (!active) {
    return 'disabled';
  }
  if (helpfulVotesOnly) {
    return 'upvotesOnly';
  }
  return 'full';
};
