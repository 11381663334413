import React from 'react';
import { Button, ButtonProps, ButtonSize as WUTButtonSize } from 'wix-ui-tpa/cssVars';
import { st, classes } from './button-extended.st.css';

type ButtonSize = keyof typeof WUTButtonSize;
type ButtonExtendedProps = {
  mapping: 'callToAction' | 'primary' | 'secondary';
  size?: ButtonSize;
} & Omit<ButtonProps, 'priority' | 'size'>;

export const ButtonExtended: React.FC<ButtonExtendedProps> = ({
  className,
  mapping,
  size,
  ...props
}) => {
  return (
    <Button
      className={st(classes.root, { mapping }, className)}
      size={size ? WUTButtonSize[size] : size}
      {...props}
    >
      {props.children}
    </Button>
  );
};
