import React from 'react';

export const DeleteIcon = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M15 4v2h4v1h-1v13H6V7H5V6h4V4h6zm2 3H7v12h10V7zm-3 2v8h-1V9h1zm-3 0v8h-1V9h1zm3-4h-4v1h4V5z"
      transform="translate(-12 -470) translate(0 306) translate(0 155) translate(12 9)"
    />
  </svg>
);
