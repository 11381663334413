import React from 'react';
import { createContext, useContextSelector } from '~commons/use-context-selector';
import { isShallowEqual } from '~commons/services/is-shallow-equal';

const RootRefContext = createContext<HTMLElement | null>(null);

export const RootRefProvider: React.FC<React.PropsWithChildren<{ rootRef: HTMLElement | null }>> = (
  props,
) => {
  return <RootRefContext.Provider value={props.rootRef}>{props.children}</RootRefContext.Provider>;
};

export const useRootRef = () => useContextSelector(RootRefContext, (ctx) => ctx, isShallowEqual);
