import { State } from '../store-types';
import { ViewModes } from '../../constants/view-modes';
import { BaseParamsState } from './base-params-types';
import { InstanceValues } from '~commons/instance/types';

export const getBasicParams = (state: State) => state.baseParams as BaseParamsState;

export const getViewMode = (state: State) => getBasicParams(state).viewMode;
export const getInstance = (state: State) => getBasicParams(state).instance;
export const getInstanceValues = (state: State) => getBasicParams(state).instanceValues;
export const isDebug = (state: State) => getBasicParams(state).isDebug;
export const isProduction = (state: State) => getBasicParams(state).isProduction;
export const isSled = (state: State) => getBasicParams(state).isSled;
export const getAppDefinitionId = (state: State) => getBasicParams(state).appDefinitionId;

export const isSite = (state: State) => getViewMode(state) === ViewModes.Site;
export const getIsEditor = (state: State) => getViewMode(state) === ViewModes.Editor;
export const getIsPreview = (state: State) => getViewMode(state) === ViewModes.Preview;
export const isDashboard = (state: State) => getViewMode(state) === ViewModes.Dashboard;
export const isSeo = (state: State) => getBasicParams(state).isSeo;

export const getInstanceValue = <K extends keyof InstanceValues>(
  state: State,
  name: K,
): InstanceValues[K] => getInstanceValues(state)?.[name];

export const getIsDemoMode = (state: State) => getInstanceValue(state, 'demoMode');
export const getBiToken = (state: State) => getInstanceValue(state, 'biToken');
export const getAid = (state: State) => getInstanceValue(state, 'aid');
export const getPermissions = (state: State) => getInstanceValue(state, 'permissions');
export const getMetaSiteId = (state: State) => getInstanceValue(state, 'metaSiteId');
export const getSiteIsTemplate = (state: State) => getInstanceValue(state, 'siteIsTemplate');

export const isLoggingEnabled = (state: State) =>
  (isDebug(state) || !isProduction(state) || isSled(state)) && !getBasicParams(state).disableLogs;
