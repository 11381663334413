import React, { RefObject } from 'react';

type FileUploadChildrenProps = {
  openFileUploadDialog: React.MouseEventHandler<HTMLElement>;
};

export interface FileUploadProps {
  dataHook?: string;
  className?: string;
  children(params: FileUploadChildrenProps): React.ClassicElement<any>;
  multiple?: boolean;
  accept?: string;
  capture?: 'user' | 'environment';
  name?: string;
  onChange(fileList: FileList): void;
  id: string;
  disabled?: boolean;
}
// Temp clone form WSR
export class FileUpload extends React.PureComponent<FileUploadProps> {
  inputRef: RefObject<HTMLInputElement>;
  constructor(props: FileUploadProps) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
  }
  render() {
    const { id, dataHook, className, children, multiple, accept, capture, name, onChange } =
      this.props;

    return (
      <div className={className} data-hook={dataHook}>
        {children({
          openFileUploadDialog: (event) => {
            // Open the file upload dialog
            this.inputRef.current?.click();

            // In case the click event comes from a non-button element
            if (event) {
              event.preventDefault();
            }
          },
        })}

        {/* An invisible input type="file" in order to open a file upload dialog */}
        <input
          id={id}
          disabled={this.props.disabled}
          type="file"
          style={{ display: 'none' }}
          ref={this.inputRef}
          multiple={multiple}
          accept={accept}
          capture={capture}
          name={name}
          onChange={(event) => {
            event.currentTarget.files && onChange(event.currentTarget.files);
            if (this.inputRef.current) {
              this.inputRef.current.value = '';
            }
          }}
        />
      </div>
    );
  }
}
