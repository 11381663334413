import forDevice from '../../../hoc/for-device';
import { GalleryModalMobile } from './gallery-modal-mobile';
import { GalleryModalDesktop } from './gallery-modal-desktop';
import { Review } from '../../../../controller/lib/reviews-api-types';

export const GalleryModal = forDevice(GalleryModalMobile, GalleryModalDesktop);

export type GalleryModalProps = {
  onClose: () => void;
  review: Review;
  activeIndex: number;
};
