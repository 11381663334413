import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import TextExtended from '../components/text-extended/text-extended';
import { classes, style } from './deep-link-not-found.st.css';

export const DeepLinkNotFound: React.FC<{}> = () => {
  const t = useTranslate();
  return (
    <div className={style(classes.root)}>
      <div className={style(classes.textContainer)}>
        <TextExtended>{t('empty-state.deep-link-not-found')}</TextExtended>
      </div>
    </div>
  );
};
