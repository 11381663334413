import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { ReviewSummary } from 'wix-ui-tpa/cssVars';
import { RatingsBreakdown as RatingsBreakdownType } from '../../../controller/lib/reviews-api-types';
import { useApi } from '../api-provider/use-api';
import { classes, st } from './ratings-breakdown.st.css';
import FavoriteFillSmall from 'wix-ui-icons-common/on-stage/FavoriteFillSmall';
import { REVIEWS_BREAKDOWN } from '../../../common/constants/data-hooks';
import { parseRatingFilterValue } from '~reviews/common/services/ordering-and-filter-utils';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const RatingsBreakdown: React.FC<{
  breakdown: RatingsBreakdownType;
  totalReviews: number;
  clickable?: boolean;
  withIcons?: boolean;
}> = ({ breakdown, totalReviews, clickable = true, withIcons = false }) => {
  const { isMobile } = useEnvironment();
  const { changeRatingFilter, biChangeFiltering } = useApi((state, actions) => ({
    changeRatingFilter: actions.changeRatingFilter,
    biChangeFiltering: actions.biChangeFiltering,
  }));
  const onRatingFilterChange = React.useCallback(
    (rating: string) => {
      biChangeFiltering({ rating, origin: 'bars' });
      changeRatingFilter({
        ratingFilter: parseRatingFilterValue(rating),
      });
    },
    [changeRatingFilter, biChangeFiltering],
  );

  const t = useTranslate();
  const reviewSummaryItems = React.useMemo(() => {
    return Object.keys(breakdown)
      .reverse()
      .map((key) => {
        const rating = Number(key);
        const value = breakdown[rating];
        return {
          value,
          max: totalReviews,
          prefixIndication: withIcons ? (
            <div className={classes.prefixContainer}>
              {rating} <FavoriteFillSmall className={classes.starIcon} />
            </div>
          ) : (
            t('reviews-summary-stars-count', { count: rating })
          ),
          showProgressIndication: true,
          customSuffixIndicationText: value.toString(),
          onClick: clickable ? () => onRatingFilterChange(key) : undefined,
          'aria-label': t('reviews-summary-rating-aria-label', { count: value, rating }),
        };
      });
  }, [breakdown, totalReviews, withIcons, t, clickable, onRatingFilterChange]);

  return reviewSummaryItems ? (
    <ReviewSummary
      items={reviewSummaryItems}
      className={st(classes.root, { mobile: isMobile })}
      data-hook={REVIEWS_BREAKDOWN}
    />
  ) : null;
};
